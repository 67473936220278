<template>
  <div id="app">
    <!-- <link rel="shortcut icon" type="image/png" href="favicon.png" /> -->
    <!-- <link rel="shortcut icon" type="image/png" href="medge.png" /> -->
    <link rel="shortcut icon" type="image/png" href="public/medge.png" />
    <!-- <link rel="shortcut icon" type="image/png" href="flanked.png" /> -->
    <!-- <link rel="shortcut icon" type="image/png" href="minf.png" /> -->
    <!-- <link rel="shortcut icon" type="image/png" href="mup.png" /> -->
    <link rel="stylesheet" href="https://bootswatch.com/4/cerulean/bootstrap.min.css"><!-- bootstrap styling resource -->
    <link href="https://fonts.googleapis.com/css?family=Exo+2&display=swap" rel="stylesheet">

    <div id="page">
      <Header />
        <div id="pageContent">
          <router-view />
            <PrivacyTOS />
        </div>
    </div>
  </div>
</template>

<style> /* global styles */
#app {
  font-family: 'Exo 2', 'Mono', monospace;
  font-size: 90%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  --mechanomyBlue: #0069ff;
}
#page {
}
#pageContent{
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
}
p{
  text-align: justify;
  text-justify: inter-word;
  /* text-justify: inter-character; */
}
a[target="_blank"]:after{
  font-family: 'sans';
  /* see https://dev.w3.org/html5/html-author/charref */
  content: "\02197"; /* &UpperRightArrow; = &x02197; as a 'hex ref'.. */
}
</style>

<script type="text/javascript">
import PrivacyTOS from '@/components/PrivacyTOS';
import * as UOP from '@/common/UserOps';
import store from '@/store/Store'
import { Component, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch
import Header from '@/components/Header'
import * as UTY from '@/common/Utility';

export default
@Component({
  components: {
    Header,
    PrivacyTOS
  }
})
class App extends Vue {
  title = 'Mechanomy Composer';

  mounted(){
    // let herestr = 'App.doupdated: ';

    this.printConsoleHeader();

    if (store.getters.getAPIToken){
      // Vue.$log.warn( herestr + 'token present, reassign to axios');
      Vue.prototype.$axios.defaults.headers.common['Authorization'] = store.getters.getAPIToken;
    } else {
      // Vue.$log.warn('token is ', store.getters.getAPIToken);
      // Vue.$log.warn( herestr + 'token not present, requesting' );
      UOP.getAPIToken();
    }
    Vue.$gtag.enabled = store.getters.areCookiesAllowed;
  }

  printConsoleHeader(){
    let dt = new Date();
    Vue.$log.info(`Welcome to the Mechanomy Composer, today is ${dt}`);
  }
}
</script>

