import Vue from 'vue';
import store from '@/store/Store'


export function strDiff( stra, strb ){
  if (stra == null || strb == null)
    return '';
  // console.log('strDiff[' + stra + ']');
  // console.log('strDiff[' + strb + ']');

  let strd = '';
  for (let i = 0; i < Math.min( stra.length, strb.length); i++){
    if (stra.charAt(i) == strb.charAt(i)){
      strd = strd + ' ';
    }else {
      strd = strd + strb.charAt(i);
    }
  }
  return strd;
}

export function promiseRejected( severity, message ){
  return {severity: severity, message: message};
}

export function handleRejectedPromise( err ){
  if (err.severity == 'off'){
    return;
  } else if (err.severity == 'debug'){
    Vue.$log.debug( 'PromiseRejected with message: ' + err.message );
  } else if (err.severity == 'info'){
    Vue.$log.info( 'PromiseRejected with message: ' + err.message );
  } else if (err.severity == 'warn'){
    Vue.$log.warn( 'PromiseRejected with message: ' + err.message );
  } else if (err.severity == 'error'){
    Vue.$log.error( 'PromiseRejected with message: ' + err.message );
  } else {
    console.error( 'PromiseRejected with error', err );
    // Vue.$log.error( 'PromiseRejected with error: ' + JSON.stringify(err) );
  }
}


export function stringArrayToMultiline( str ){
  let ret = '';
  for ( let s of str ){
    if (typeof(s) == 'string')
      ret = ret + s + '\n';
  }
  return ret;
}

