import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import {userStore} from '@/store/UserStore';
import {modelStore} from '@/store/ModelStore';
import {resultStore} from '@/store/ResultStore';
import {simAdStore} from '@/store/SimAdStore';
import {uiStore} from '@/store/UIStore';

Vue.use(Vuex);

const persistUser = new VuexPersist({
  key: 'MechanomyComposer',
  storage: window.localStorage,
  modules: ['user', 'model']//==Vuex.module.user
})

export default new Vuex.Store({
  plugins: [persistUser.plugin],
  modules: {
    user: userStore,
    model: modelStore,
    result: resultStore,
    ad: simAdStore,
    ui: uiStore
  }
});
