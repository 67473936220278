import Vue from 'vue';
import VueCookie from 'vue-cookies';
import VueLogger from 'vuejs-logger';
import axios from 'axios'

import VuePageTitle from 'vue-page-title';
import VueGtag from 'vue-gtag';

//other parts
import router from './router';
import store from '@/store/Store'

//views & components
import App from './App'
import Header from './components/Header'
import ModelInfo from './components/ModelInfo';
import ResultInfo from './components/ResultInfo';
import ModelEditor from './components/ModelEditor'
import Graph from './components/Graph'
import RunningAd from './components/RunningAd'
import PrivacyTOS from '@/components/PrivacyTOS';
// import PublicModelInfoVue from '@/components/PublicModelInfoVue';
import PublicModelInfo from '@/components/PublicModelInfo';
import RunningModel from '@/components/RunningModel';

import Model from './views/Model';
import User from './views/User';
import Wild from './views/Wild';
import About from '@/views/About';
import Home from '@/views/Home';
import RegisterNative from '@/views/RegisterNative';

//logging
const isProduction = process.env.NODE_ENV === 'production';
const logops = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug', //limit seveity to debug+
    // logLevel : isProduction ? 'error' : 'info', //limit seveity to info+
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : false, //is usually useless
    separator: '|',
    showConsoleColors: true
};
Vue.use(VueLogger, logops);

// Vue.$log.debug('main.js');
// Vue.$log.debug('debug'); //dev messages
// Vue.$log.info('info'); //info about events, mainly server calls
// Vue.$log.warn('warn'); //something unexpected
// Vue.$log.error('error'); //something is wrong, can proceed
// Vue.$log.fatal('fatal'); //something failed. no further ops possible

Vue.use( VuePageTitle, { prefix: 'Mechanomy Composer - ', router: router }); //for setting page titles

Vue.use(VueCookie);

Vue.use(VueGtag, { config: {id: 'UA-150145943-1'}, enabled: false}, router );

Vue.config.productionTip = false;

const axinst = axios.create( {
  // baseURL: 'https://localhost:2442',
  // baseURL: 'http://165.227.210.82:2442',
  baseURL: 'https://api.mechanomy.com:2442',
});
axinst.defaults.withCredentials = false; //this true leads to CORS errors..
Vue.prototype.$axios = axinst;

let vm = new Vue({ /* eslint-disable-line */
  store,
  router,
  components: {Graph, Header, ModelEditor, ModelInfo, PrivacyTOS, PublicModelInfo, ResultInfo, RunningAd, RunningModel, About, Home, Model, RegisterNative, User, Wild },
  render: h => h(App)
}).$mount('#app');


