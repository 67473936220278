<template>
  <div id="about">
    <h1>About the Model Composer</h1>
    <p>Mechanomy is building the Model Composer to introduce users to the power of system modeling and composition.
    This power is prinicipally provided by the <a href="https://openmodelica.org/" target="_blank">Open Modelica</a> project that writes and releases a compiler and simulation engine for the <a href="https://www.modelica.org/" target="_blank">Modelica</a> systems modeling language.
    We use <a href="https://nodejs.org/en/about/" target="_blank">Node.js</a> on server while the client built in <a href="https://vuejs.org/" target="_blank">Vue.js</a> with model editing in <a href="https://codemirror.net/" target="_blank">CodeMirror</a> and plotting in <a href="https://www.chartjs.org/" target="_blank">ChartJS</a>.</p>

    <p>Mechanomy is possible because both the developers of Modelica and web frameworks have embraced open technologies and fostered open user communities. Thanks to all the developers.</p>

    <p>
    The Composer is in <strong><i>alpha</i></strong> release; if you run into a bug, please send us a <a href="mailto:bugs@mechanomy.com?subject=Composer Bug&body=Please include the model text, the expected action, and what happened instead. Thank you!">note</a>.
    We are developing a number of improvements and extensions to the core services; if you want to be a beta tester or have feedback or suggestions, <a href="mailto:feedback@mechanomy.com?subject=Feedback and Suggestions">we'd love to include you in the experiments</a>.
    </p>
    <p> - Ben and the Mechanomy team</p>

    <!-- <h1>Composer Stats:</h1> -->
    <!-- # of models -->
    <!-- # of users -->
    <!-- # of ... -->
    <!-- Version {{ versionString }} -->
  </div>
</template>

<style scoped>

#about{
  /* text-align: center; */
  margin-top: 5%;
  margin-left: 5%;
  width: 50%;
}
</style>

<script type="text/javascript">

import { Component, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch

export default
@Component({
  components: {
  }
})
class About extends Vue {

  mounted(){
  }
}
</script>
