import Vue from 'vue';

export const modelStore = {
  state: {
    modelID: '',
    userModelName: 'New Model',
    modelSyntax: ''
  },

  getters: {
    getModelName(state){
      if (!state.userModelName)
        return false;
      return state.userModelName;
    },
    getModelID(state){
      if (!state.modelID)
        return false;
      return state.modelID;
    },
    getModelSyntax(state){
      if (!state.modelSyntax){
        Vue.$log.debug('getModelSyntax() store has no modelSyntax');
        return false;
      }
      // Vue.$log.debug('getModelSyntax() ' + state.modelSyntax.substr(0,100));
      return state.modelSyntax;
    },
  },
  mutations: {
    setModelName(state, newname){
      // Vue.$log.debug('setModelName set to ' + newname );
      state.userModelName = newname;
    },
    setModelID(state, mid){
      state.modelID = mid;
    },
    setModelSyntax(state, syntax){
      // Vue.$log.debug('setModelSyntax() ' + syntax.substr(0,100));
      state.modelSyntax = syntax;
    }
  },
  actions: {
    modelLogOut( {state,commit,rootState} ){
      Vue.$log.info('modelLogOut');
      state.modelID = '';
      state.userModelName = '';
      state.modelSyntax = '';
    }
  }
}


