<template>
  <div id="wild">
    <h1>WILD</h1>
  </div>

</template>

<script type="text/javascript">
export default {
  name: 'Wild'
}
</script>
