<template>
  <div id="ModelEditorVue">

    <div id="modelArea">
      <div id="codemArea">
        <textarea id="codemTextArea"></textarea>
        <button v-on:click="runModel()" class="btn btn-primary btn-block">Run Model [Alt+R]</button>
      </div><!-- infoColumn -->

      <div id="infoColumn">
        <RunningModel />
        <!-- <SyntaxHint /> -->
        <div id="errorMessages" v-for="(msg,index) of errorMessages" v-bind:key="index">
          <div class="alert alert-warning" role="alert">Simulation Error {{ index }}: {{ msg }} </div>
        </div>
        <div id="warningMessages" v-for="(msg,index) of warningMessages" v-bind:key="index">
          <div class="alert alert-info" role="alert">Simulation Warning {{ index }}: {{ msg }} </div>
        </div>

        <ResultInfo id="resultInfo" v-if="result" v-bind:result="result" v-bind:expandProp="true"/>
      </div><!-- infoColumn -->
    </div> <!--modelArea -->


    <div id="analyzeContainer">
      <div id="forContainer" v-for="plot of plotData" v-bind="plotData" v-bind:key="plot.id">
        <Graph v-bind:graph="plot" />
      </div>
    </div><!-- analyzeContainer -->


  </div><!--ModelEditorVue-->
</template>

<style scoped>
#ModelEditorVue{
  position: relative;
  /* border: 2px dashed orange; */
}
#modelArea{
  /* display: block; */
  display: inline-block;
  /* width: 80%; */
  width: 100%;
  /* max-width: 800px; */
  /* border: 2px dashed cyan; */
  /* overflow:hidden; /* don't show overflowing content */
  position: relative;
  overflow: auto;
}
#codemArea{
  display: inline-block;
  width: 80%;
  vertical-align: top;
  /* border: 2px dashed magenta; */
  border-color: #bfbfbf;
  border-style: solid;
  border-width: 0px 2px 0px 0px;
}
#codemTextArea{
}
#infoColumn{
  display: inline-block;
  width: 20%;
  vertical-align: top;
  /* border: 2px dashed purple; */
  /* overflow-y:scroll; /* don't show overflowing content */
}
#errorMessages{
  /* border: 2px dotted orange; */
}
#resultInfo{
  /* border: 2px dotted black; */
}
#analyzeContainer{
  /* properties for the parent container https://www.w3schools.com/css/css3_flexbox.asp */
  display: flex; /* make parent container flexible
  display: inline-flex; /* make parent container flexible */
  flex-direction: row; /* prefer to arrange in rows */
  flex-wrap: wrap; /* wrap items if necessary */
  justify-content: center;
  overflow: auto;
  position: relative;
  /* border: 2px solid green; */
}
#forContainer{
  /* the flex item properties are: order, flex-grow, flex-shrink, flex-basis, flex, align-self */
  flex-basis: 500px; /* initial width of box */
  flex-grow: 1; /* (0:big), 1 = default */
  flex-shrink: 1; /* (0:big), 1 = default */
  display: inherit;
  overflow: auto;
  margin: 1em;
  max-width: 70%;
  /* border: 2px solid cyan; */
}
@import "../style/codemirror.css"; /*codemirror styles must be put in the .css, can't be overridden here*/
</style>


<script type="text/javascript">

import { Component, Watch, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch
import store from '@/store/Store'

import CodeMirror from 'codemirror';
import '../../node_modules/codemirror/mode/modelica/modelica.js';
import '../../node_modules/vue-chartjs/dist/vue-chartjs.js';

import RunningModel from '@/components/RunningModel'
import Graph from '@/components/Graph'
import * as MEH from '@/common/ModelEditorHelper';
import * as PAR from '@/common/ParseModel';
import * as ANA from '@/common/AnalyzeHelper.js';
// import * as GPH from '../common/GraphHelper';
// import * as UserOps from '../common/UserOps';
import * as UTY from '@/common/Utility';
// import * as RIV from '../components/ResultInfoVue'
import ResultInfo from '../components/ResultInfo'

export default
@Component({
  components: {
    RunningModel,
    Graph,
    ResultInfo
    // 'ResultInfoVue': RIV.ResultInfoVue
  }
})
class ModelEditor extends Vue {

  plotData = []; //array of plot data

  codem; //reference to the codeMirror

  errorMessages = [];
  warningMessages = [];

  //create a ResultInfoVue with the most recent results
  result = false; //results object, start as false
  get areResultsValidThis(){
    return store.getters.areResultsValid;
  }

  @Watch('areResultsValidThis') onResultChange( newv, oldv ){ /* eslint-disable-line */
    // Vue.$log.info('store.areResultsValid was[' + oldv + '] is ['+ newv +']');
    if (!oldv && newv){ //areResultsValid == true;
      // this.result = store.getters.getResultData;
      this.result = store.getters.getResultObject;
      if (this.result != false){
        return true;
      }
    }
  }

  get getErrorMessages(){
    return store.getters.getErrorMessages;
    // let errObjs = store.getters.getErrorMessages;
    // let msgs = [];
    // for (let eo of store.getters.getErrorMessages){
    //   msgs.push( eo.message );
    // }
    // return msgs;
  }

  @Watch('getErrorMessages') onResultChange2( newv, oldv ){ /* eslint-disable-line */
    // this.errorMessages = store.getters.getErrorMessages;
    // Vue.$log.info('store.errorMessages is [' + store.getters.getErrorMessages );
    let errObjs = store.getters.getErrorMessages;
    this.errorMessages = [];
    for (let eo of store.getters.getErrorMessages){
      this.errorMessages.push( eo.message );
    }
  }

  mounted() {
    // Vue.$log.info('ModelEditor mounted');
    let pthis = this;
    let herestr = 'ModelEditor.mounted: ';
    // pthis.codem = CodeMirror.fromTextArea( document.getElementById('codemTextArea') ); //create the codeMirror
    pthis.codem = CodeMirror.fromTextArea( document.getElementById('codemTextArea'), {mode: 'modelica', lineNumbers: true } ); //create the codeMirror
    pthis.codem.on('cursorActivity', () => pthis.callbackCursorActivity()); //register callback
    pthis.codem.on('change', () => pthis.callbackChange()); //register callback

    window.addEventListener('keyup', (eve)=>{
      // console.log(eve );
      if (eve.altKey && eve.code === 'KeyR')
        pthis.runModel();
      if (eve.altKey && eve.code === 'Enter')
        pthis.runModel();
    });
    // this sends the request but immediately cancels it; eg page navigation away..
    // window.addEventListener('beforeunload', (eve)=>{
    //   MEH.sendModelToServer()
    //     .then( (res)=>{
    //       Vue.$log.info('window closing, send model to server', res);
    //     }).catch( (err) => {
    //       UTY.handleRejectedPromise( err );
    //     }); //sendModelToServer
    //   return null;
    // });

    store.commit('clearWarningMessages'); //clear out any old messages
    store.commit('clearErrorMessages'); //clear out any old messages

    //load model content
    let mdlid = pthis.$route.params.modelID; //set by PublicModelInfo or ModelInfo
    Vue.$log.info('ModelEditor: loading model ' + mdlid);
    if (pthis.$route.params.modelID){
      Vue.$log.info('ModelEditor: loading model ' + mdlid);

      if (mdlid == 'blank'){
        pthis.codem.setValue( MEH.loadModelExample('blank') );
        pthis.$router.push({ name: 'Model'});
        store.commit('setModelSyntax', pthis.codem.getValue());
      }else if (mdlid == 'formatReference'){
        pthis.codem.setValue( MEH.loadModelExample('formatReference') );
        pthis.$router.push({ name: 'Model'});
        store.commit('setModelSyntax', pthis.codem.getValue());
      }else if (mdlid == 'bouncingBall'){
        pthis.codem.setValue( MEH.loadModelExample('bouncingBall') );
        pthis.$router.push({ name: 'Model'});
        store.commit('setModelSyntax', pthis.codem.getValue());
      }else if (mdlid == 'simplePendulum'){
        pthis.codem.setValue( MEH.loadModelExample('simplePendulum') );
        pthis.$router.push({ name: 'Model'});
        store.commit('setModelSyntax', pthis.codem.getValue());
      } else{
        MEH.loadModel( mdlid )
          .then( (syn)=>{
            pthis.codem.setValue( syn );
            // this.codem.setValue( store.getters.getModelSyntax);
            // Vue.$log.info('ModelEditor loadModel syntax: ' + syn );
            pthis.$router.push({ name: 'Model'});//remove the modelID from the path
          })
          .then( ()=>{
            store.commit('setModelSyntax', pthis.codem.getValue());
          })
          .catch( (err)=>{
            Vue.$log.warn('ModelEditor loadModel had error: ' + JSON.stringify( err ) );
          });
      }
    } else {
      Vue.$log.info('ModelEditor loadModel had else, modelID is ' + pthis.$route.params.modelID );

      let syn = store.getters.getModelSyntax;
      if (syn){
        Vue.$log.info('setting editor from store');
        pthis.codem.setValue( syn ); //restore model
      } else {
        Vue.$log.info('setting editor from default');
        pthis.codem.setValue( MEH.loadModelExample('bouncingBall') );
      }
    }

    //give a slight delay to finish loading things, then refresh to disappear the horizontal scroll
    setTimeout( ()=>{
      pthis.codem.refresh(); //refresh is essential to keeping the codemirror and vue renderers in sync, put here as this call is frequent
    }, 100);
  }; //mounted

  beforeDestroy(){ //before navigating to another page in the SPA
    MEH.sendModelToServer()
      .then( (res)=>{
        Vue.$log.info('model changed, sent to server', res);
      }).catch( (err) => {
        UTY.handleRejectedPromise( err );
      }); //sendModelToServer

    this.codem = null;
  }

  callbackCursorActivity() {
    let pthis = this;
    // Vue.$log.debug('callbackCursorActivity');
    pthis.codem.refresh(); //refresh is essential to keeping the codemirror and vue renderers in sync, put here as this call is frequent


    // ANA.makeGraphs( pthis.codem.getValue())
    //   .then( (pdata)=>{ pthis.plotData = pdata; } )
    //   .catch( (err) => UTY.handleRejectedPromise( err ) );

    PAR.isModelSyntaxValid( pthis.codem.getValue() )
      .then( (synvalid) => {
        // console.log( 'syntax is valid synvalid', synvalid);
      }, (rea) => {
        UTY.handleRejectedPromise( rea );
      })
      .catch( (err)=>{
        UTY.handleRejectedPromise( err );
      });
  }

  timeSinceLast = 0;
  callbackChange() {
    let pthis = this;
    // Vue.$log.debug('\ncallbackChange');
    store.commit('setModelName', PAR.findModelName(pthis.codem.getValue()).modelName );
    store.commit('setModelSyntax', pthis.codem.getValue());

    let dnow = Date.now();
    if (10*1000 < Math.abs( dnow - pthis.timeSinceLast ) ){
      pthis.timeSinceLast = dnow;
      // console.log('dnow' + dnow + ' tsl' + pthis.timeSinceLast );
      // //'save' model on every change...many requests, but easier than watching for window close?
      MEH.sendModelToServer()
        .then( (res)=>{
          Vue.$log.info('model changed, sent to server', res);
        }).catch( (err) => {
          UTY.handleRejectedPromise( err );
        }); //sendModelToServer
    }//savemodel

    //re-plot results
    if (store.getters.areResultsValid ){
      ANA.makeGraphs()
        .then( (res)=>{
          // Vue.$log.debug('plotData assigned:', res);
          pthis.plotData = res;
        }, (rea) => {
          UTY.handleRejectedPromise( rea );
        }).catch( (err) => {
          UTY.handleRejectedPromise( err );
        }); //makeGraphs
    }
  } //callbackChange

  runModel() {
    // console.log( 'runModel' );
    let pthis = this;
    if (pthis.codem == null){
      // Vue.$log.warn('runModel called before codem ready');
      return;
    }
    store.commit('setModelSyntax', pthis.codem.getValue());
    pthis.result = false;

    store.commit('clearInfoMessages'); //clear out any old messages
    store.commit('clearErrorMessages'); //clear out any old messages

    PAR.isModelSyntaxValid() .then( ()=>{
      MEH.sendModelToServer()
        .then( ()=>{

          MEH.runSimulation()
            .then( (info)=>{
              Vue.$log.debug('runSimulation done, info=', info );

              MEH.retrieveResults()
                .then( ()=>{

                  ANA.makeGraphs()
                    .then( (res)=>{
                      // Vue.$log.debug('plotData assigned:', res);
                      pthis.plotData = res;
                    }, (rea) => {
                      UTY.handleRejectedPromise( rea );
                    }).catch( (err) => {
                      UTY.handleRejectedPromise( err );
                    }); //makeGraphs

                }, (rea) => {
                  UTY.handleRejectedPromise( rea );
                }).catch( (err) => {
                  UTY.handleRejectedPromise( err );
                }); //retrieveResults

            }, (rea) => {
              // console.log('runSimulation rea', rea );
              // pthis.showSimError = rea.showSimError;
              // pthis.showSimInfo = rea.showSimInfo;
              pthis.simMessage = UTY.stringArrayToMultiline( rea.messages );

            }).catch( (err) => {
              Vue.$log.warn('runSimulation err', err );
              // pthis.showSimError = err.showSimError;
              // pthis.showSimInfo = err.showSimInfo;
              pthis.simMessage = UTY.stringArrayToMultiline( err.messages );
            }); //runSimulation

        }, (rea) => {
          UTY.handleRejectedPromise( rea );
        }).catch( (err) => {
          UTY.handleRejectedPromise( err );
        }); //sendModelToServer
    }).catch( (err) => {
      UTY.handleRejectedPromise( err );
    }); //isModelSyntaxValid()

  }//runModel
}; //exportDefault

</script>


