<template>
  <div id="home">
    <!-- <Header /> -->
    <h1>Welcome to the Mechanomy Model Composer</h1>
    <p>
    The Model Composer is a web-based editor and simulator for Modelica models, allowing you to explore the inner workings of physical systems.

    </p>
    <div>
      <h3>New to Modelica?</h3>
      <p>These models are commented to get you rolling quickly:</p>
        <span id="publicModels" v-for="model of allmodels" v-bind:item="model" v-bind:key="model.modelID">
          <PublicModelInfo v-bind:model=model />
        </span>
    </div>

    <h3>Need help?</h3>
    <p>Here are the most useful examples, explanations, guides, and answers:</p>
    <ul>
      <li>Modelica <a target=_blank href="https://composer.mechanomy.com/wiki/index.php/Modelica_Format_Reference">format reference</a></li>
      <li>Common modeling <a target=_blank href="https://composer.mechanomy.com/wiki/index.php/Common_Errors">errors</a></li>
      <li>Search and ask questions on <a target=_blank href="https://stackoverflow.com/questions/tagged/modelica">StackOverflow</a></li>
      <!-- <li><a target=_blank href="">Getting started in physical system simulation</a></li> -->
      <li>A high-level <a target=_blank href="https://mechanomy.com/2019/10/a-tour-of-modelica/">introduction to Modelica</a></li>
      <!-- <li><a target=_blank href="https://www.modelica.org/documents">Open Modelica Specification</a></li> -->
      <li>Modelica Standard Library <a target=_blank href="https://build.openmodelica.org/Documentation/Modelica.html">documentation</a></li>
      <li>Dr. Michael Tiller's <a target=_blank href="https://mbe.modelica.university/">Modelica by Example</a></li>
      <!-- <li><a target=_blank href="">Overview of the Mechanomy Composer</a></li> -->
      <!-- <li><a target=_blank href="">Upvote for the next example</a></li> -->
    </ul>
  </div>
</template>

<script type="text/javascript">

import { Component, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch
import Header from '@/components/Header'
import PublicModelInfo from '@/components/PublicModelInfo';
import * as UTY from '@/common/Utility';
import store from '@/store/Store'

export default
@Component({
  components: {Header,
    PublicModelInfo
  }
})
class Home extends Vue {
  allmodels = [];
  allresults = [];
  mounted(){
    // this.$log.debug('Home.vue mounted()');
    this.getPublicModels();
  }

  getPublicModels(){
    // this.$log.debug('Home.vue getAllModels()');

    var pthis = this;//keep a reference to RunModel's this for use in callbacks
    pthis.allmodels = [];

    pthis.$axios.get('/api/public/getModels/')
      .then( res => {
        store.commit('setIsServerDown', false);
        if (0 < res.data.length){
          // this.$log.debug('Home.vue received models', res);
          pthis.allmodels = res.data;
        } else {
          Vue.$log.debug('Home.vue no public models available', res);
        }
      })
      .catch( err => {
        store.commit('setIsServerDown', true);
        Vue.$log.error('Home.vue getPublicModels error', err);
      });
  }//getPublicModels
}
</script>

<style scoped>
#home{
  margin-top: 5em;
}
/* #publicModels{ */
/*   margin-left: 1em; */
/* } */
h3{
  margin-top: 2em;
}
</style>




