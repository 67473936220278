import Vue from 'vue';
import bcrypt from 'bcryptjs';

import store from '@/store/Store'
import * as UTY from '@/common/Utility';

export function interceptURLToken( ){ //passport-google redirects the user, placing token in the url query, intercept it and correct the uri
  // Vue.$log.debug('interceptURLToken');
  //uri looks like: http://localhost:8080/#/user?eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlblR5cGUiOiJuZXdiIiwic2VlZCI6IjE1Njg5MjAxMjUwNDFNb3ppbGxhLzUuMCAoWDExOyBVYnVudHU7IExpbnV4IHg4Nl82NDsgcnY6NjkuMCkgR2Vja28vMjAxMDAxMDEgRmlyZWZveC82OS4wIiwiZmluZ2VySGFzaCI6IjVjNjEzNjYzMzI3ODFjN2Q3ZTlmNDhiNmQ3NTc5NTdkIiwiaXNzdWVkIjoxNTY4OTIwMTI1MDc5LCJpYXQiOjE1Njg5MjAxMjV9.elWx8dhEI6-ei7VFtI8G-YYmqmB5HU4eZtWqsdCbRUw
  let uri = window.location;

  let iq = uri.hash.search(/\?/); //index of the ?
  // console.log(iq);
  if ( iq < 0)
    return;

  let tok = uri.hash.substring( iq+1 ); //after ?
  // console.log(tok);
  if( tok && 0 < tok.length ){
    let newuri = uri.origin + '/' + uri.hash.substring(0,iq); //removing the token from the uri
    Vue.$log.debug('page was [' + uri + '] setting page to [' + newuri +']');
    window.location.replace( newuri );
    store.dispatch('setAPIToken', tok)
  // } else {
  //   Vue.$log.info('interceptURLToken did not find a token for uri:' + uri );
  }
}//intercpetURLToken

export function getAPIToken(){
  // let herestr = 'UOP.getAPIToken: ';
  // Vue.$log.info( herestr +  'getting' );
  return new Promise( (resolve,reject) => {
    // Vue.$log.info( herestr +  'token is [' +  store.getters.getAPIToken +']' );
    let tok = store.getters.getAPIToken;
    if ( tok ){
      store.commit('setIsServerDown', false);
      resolve( tok );
    } else {
      let clientSeed = {seed: Date.now().toString() + navigator.userAgent};
      // Vue.$log.info( clientSeed );

      Vue.prototype.$axios.post('/api/getAPIToken', clientSeed)
        .then( (res) => {
          // Vue.$log.info( herestr + 'responsed=' + res.data.token);
          // Vue.$log.info( res );

          // pthis.$store.commit('setAPIToken', res.data.token );
          // resolve( res.data.token );

          store.dispatch('setAPIToken', res.data.token)
          .then( ()=>resolve( res.data.token ));
        })
        .catch( (err) => {
          store.commit('setIsServerDown', true);
          reject( UTY.promiseRejected('error','UserOps.getAPIToken rejected with err:' + err ));
        });
    }
  });
}

export function getUserType(){
  // let herestr = 'UOP.getUserType: ';
  // Vue.$log.info( herestr);

  return new Promise( (resolve,reject) => {
    if (store.getters.getAPIToken){
      Vue.prototype.$axios.get('/api/user/getUserType')
        .then( (res) => {
          // Vue.$log.info( herestr + 'responsed=');
          // Vue.$log.info( res );

          store.commit('setIsServerDown', false);
          store.commit('setUserType', res.data );
          resolve( res.data );
        })
        .catch( (err) => {
          store.commit('setIsServerDown', true);
          reject( UTY.promiseRejected('error','UserOps.getUserType rejected with err:' + err ));
        });
    } else {
      reject( UTY.promiseRejected('error','UserOps.getUserType cannot get type without api token' ));
    }
  });
}

export function getUserName(){
  // let herestr = 'UOP.getUserName: ';
  // Vue.$log.info( herestr);

  return new Promise( (resolve,reject) => {
    if (store.getters.getAPIToken){
      Vue.prototype.$axios.get('/api/user/getUserName')
        .then( (res) => {
          store.commit('setIsServerDown', false);
          store.commit('setUserName', res.data );
          resolve( res.data );
        })
        .catch( (err) => {
          store.commit('setIsServerDown', true);
          reject( UTY.promiseRejected('error','UserOps.getUserName rejected with err:' + err ));
        });
    } else {
      reject( UTY.promiseRejected('error','UserOps.getUserName cannot get type without api token' ));
    }
  });
}

export function getUserID(){
  // let herestr = 'UOP.getUserID: ';
  // Vue.$log.info( herestr);

  return new Promise( (resolve,reject) => {
    if (store.getters.getAPIToken){
      Vue.prototype.$axios.get('/api/user/getUserID')
        .then( (res) => {
          store.commit('setIsServerDown', false);
          store.commit('setUserID', res.data );
          resolve( res.data );
        })
        .catch( (err) => {
          store.commit('setIsServerDown', true);
          reject( UTY.promiseRejected('error','UserOps.getUserID rejected with err:' + err ));
        });
    } else {
      reject( UTY.promiseRejected('error','UserOps.getUserID cannot get type without api token' ));
    }
  });
}

export function getUserModels(){ //get list of models
  let herestr = 'UserOps.getUserModels: ';
  // Vue.$log.debug(herestr);

  return new Promise( (resolve, reject) => {
    Vue.prototype.$axios.get('/api/user/getModels/' )
      .then( res => {
        if (0 < res.data.length){
          // Vue.$log.debug(herestr + 'apiUserModels returned', res);
          store.commit('setUserModels', res.data);
          store.commit('setIsServerDown', false);
          resolve( true );
        }
      })
      .catch( err => {
        // Vue.$log.error(herestr + 'apiUserModels caught err', err );
        // return false;
        if (err.response ) { // The request was made and the server responded with a status code that falls out of the range of 2xx
          store.commit('setIsServerDown', false);
          Vue.$log.debug(herestr + 'getUserModels error has response=' + err.response.data.message);
          Vue.$log.debug(err.response.data);
          Vue.$log.debug(err.response.status);
          Vue.$log.debug(err.response.headers);
        } else if (err.request) { // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
          Vue.$log.debug(herestr + 'getUserModels error does not have response, request was', err.request);
          // console.log(err.request);// eslint-disable-line
          store.commit('setIsServerDown', true);
        } else { // Something happened in setting up the request that triggered an Error
          Vue.$log.error(herestr + 'apiUserModels caught err', err);
          store.commit('setIsServerDown', true);
        }
        reject( UTY.promiseRejected( 'error', 'apiUserModels caught err:' + err ));
      });
  });
}//getUserModels

export function getUserResults(){ //get list of models
  // Vue.$log.debug('UserOps.getUserResults');

  return new Promise( (resolve, reject) => {
    Vue.prototype.$axios.get('/api/user/getResults')
      .then( res => {
        if (0 < res.data.length){
          // Vue.$log.debug('apiUserResults returned', res);
          store.commit('setIsServerDown', false);
          store.commit('setUserResults', res.data);
          resolve( true );
        }
      })
      .catch( err => {
        store.commit('setIsServerDown', true);
        Vue.$log.error('apiUserResults caught error', err);
        reject( UTY.promiseRejected('error', 'apiUserResults caught error: ' + err ));
      });
  });
}//getUserModels

export function loginNative_this( pthis ){
  let email = pthis.user.email;
  let pass = pthis.user.pass;

  return loginNative( email, pass );
}

export function loginNative(email, pass){
  let herestr = 'UserOps.loginNative: ';

  let usr = {email: email.toLowerCase(), phash: pass };

  return new Promise( (resolve,reject) => {
    //hash password client side
    bcrypt.genSalt(10, (err1, salt)=> bcrypt.hash(usr.phash, salt, (err2, hash)=>{// eslint-disable-line
      if(err2) throw err2;

      Vue.prototype.$axios.post( '/api/login/native', usr )
        .then((res)=>{
          if (res.data.token){
            Vue.$log.debug(herestr + 'logged in');
            store.commit('setAPIToken', res.data.token );
            resolve( true );
          } else {
            Vue.$log.warn(herestr + 'failed logging in');
            resolve( false );
          }
        }, (rea) => {
          // Vue.$log.info(herestr + ' rejected because: ' + JSON.stringify(rea) );
          if (rea.response.status == 401){
            reject( UTY.promiseRejected('info', 'could not log in: user is not registered or password is wrong' ));
          } else {
            reject( UTY.promiseRejected('error', 'could not log in: ' + JSON.stringify( rea ) ));
          }
        })
        .catch( (err) => {
          // Vue.$log.error(herestr + 'failed login/native: ' + JSON.stringify( err ));
          reject( UTY.promiseRejected('error', 'failed login/native caught error: ' + err ));
        });
    }));
  });
}//loginNative

export function loginGoogle() { //v-on:submit="loginGoogle"
  Vue.$log.debug('loginGoogle');
  //configure paths through console.developers.google.com
  window.location.href = Vue.prototype.$axios.defaults.baseURL + '/api/login/google';
}//loginGoogle

export function logout(){
  Vue.$log.debug('logout');

  return new Promise( (resolve,reject) => {
  Vue.prototype.$axios.get( '/api/logout')
    .then((res)=>{
      // Vue.$log.debug('apiLogout response', res);
      Vue.$log.debug('logged out');
      store.commit('logOut');
      resolve( true );
    })
    .catch( (err) => {
      reject( UTY.promiseRejected('error', 'logout caught error: ' + err ));
    });
  });
}//logout



