<template>
  <div id="user">

    <div v-if="ifUserTypeNewb" class="loginout">

      <div class="createAccount">
        <div class="card card-body">
          <h1 class="text-left mb-3">New?</h1>
          <router-link :to="{name: 'RegisterNative'}" tag="button" class="btn btn-primary btn-lg">Create an account</router-link>
          <p>To have your models, results, and graphs saved.</p>
        </div> <!-- card -->
      </div> <!-- createAccount -->

      <div class="login">
        <div class="card card-body">
          <h1 class="text-left mb-3">Login:</h1>
          <button v-on:click="loginGoogle" class="btn btn-primary btn-lg">Login with Google</button>
          <hr >
          <form v-on:submit.prevent="loginNative">
            <div class="form-group">
              <input type="email" id="email" name="email" class="form-control" placeholder="Email" v-model="user.email">
            </div>

          <div class="form-group">
            <input type="password" id="password" name="password" class="form-control" placeholder="Password" v-model="user.pass">
          </div>
          <button type="submit" class="btn btn-primary btn-lg" style="width:100%">Login with email</button>
          </form>
          <div class="alert alert-warning" role="alert" v-show="showLoginWarning"> {{ loginMessage }} </div>
        </div> <!-- card -->
      </div> <!-- login -->
    </div> <!--if newb-->

    <div v-else class="loginout">
      <div class="card card-body">
        <div style="display: inline-block"> <!-- this makes the card full&#45;width -->
          <h1 class="text-left mb-3">Welcome, {{getUserName}}</h1>
          <button v-on:click="logout" class="btn btn-outline-primary btn-md d-inline-block">LogOut?</button>
        </div>
      </div> <!-- card -->
    </div><!-- ifNewb -->


    <div class="referenceModels">
      <div class="card card-body">
        <div style="display: inline-block"> <!-- this makes the card full&#45;width -->
          <h1>Reference models:</h1>
          <router-link :to="{name: 'Model', params:{modelID:'blank'}}" tag="button" class="btn btn-primary btn-md d-inline-block">Open Blank Model</router-link>&emsp;
          <router-link :to="{name: 'Model', params:{modelID:'formatReference'}}" tag="button" class="btn btn-primary btn-md d-inline-block">Open FormatReference</router-link>&emsp;
          <router-link :to="{name: 'Model', params:{modelID:'bouncingBall'}}" tag="button" class="btn btn-primary btn-md d-inline-block">Open BouncingBall</router-link>&emsp;
          <router-link :to="{name: 'Model', params:{modelID:'simplePendulum'}}" tag="button" class="btn btn-primary btn-md d-inline-block">Open SimplePendulum</router-link>
        </div>
      </div>
      </div>

    <div class="yourModels">
      <div class="card card-body">
        <h1>Your models:</h1>
        <ul id="modelList">
          <li v-for="model of getUserModels" v-bind:item="model" v-bind:key="model._id">
            <ModelInfo v-bind:model=model />
          </li>
        </ul>
        <h1>Your results:</h1>
        <ul id="resultList">
          <li v-for="result of getUserResults" v-bind:item="result" v-bind:key="result._id" v-bind:expandProp="expandResultInfo">
            <ResultInfo v-bind:result=result />
          </li>
        </ul>
      </div>
    </div>

  </div><!-- user -->
</template>

<style scoped>
.btn-primary code{
  color: #ffffff;
}
.btn-outline-primary code{
  color: var(--mechanomyBlue);
}
#cardDefault{
  display:inline-block;
}
.loginout{
  display:inline-block;
  width: 100%;
}
.createAccount{
  display:inline-block;
  width: 50%;
  margin-top: 1em;
  margin-right: 1%;
}
.login{
  display:inline-block;
  width: 49%;
  margin-top: 1em;
}
.referenceModels{
  display:block;
  width: 100%;
  margin-top: 1em;
}
.yourModels{
  display:inline-block;
  width: 100%;
  margin-top: 1em;
}

</style>

<script type="text/javascript">

import store from '@/store/Store'
import ModelInfo from '../components/ModelInfo'
import ResultInfo from '@/components/ResultInfo'

import * as UOP from '../common/UserOps';
import { Component, Vue } from 'vue-property-decorator'; /* eslint-disable-line */ //https://github.com/kaorun343/vue-property-decorator#Watch

export default
@Component({
  components: {
    ResultInfo,
    ModelInfo
  }
})
class User extends Vue {
  user = {userName: '', email:'', pass:'', type: ''};
  expandResultInfo = true;

  showLoginWarning = false;
  loginMessage = '';

  //computed
  get ifUserTypeNewb(){
    let typ = store.getters.getUserType;
    return typ === 'newb';
  }

  get getUserName(){
    // this.$log.debug('User.vue getUserName recomputed');
    let ret = store.getters.getUserName;
    if (!ret)
      ret = 'New User';
    return ret;
  }
  get getUserModels(){
    return store.getters.getUserModels;
  }
  get getUserResults(){
    return store.getters.getUserResults;
  }

  mounted(){ //Methods to be mixed into the Vue instance. You can access these methods directly on the VM instance, or use them in directive expressions. All methods will have their this context automatically bound to the Vue instance.
    // this.$log.debug('User.vue mounted()');
    UOP.interceptURLToken();

    this.updateUser();
  }

  async updateUser(){
    // this.$log.debug('updateUser');
    let typ = UOP.getUserType( ) //eslint-disable-line
      .then( ()=>{
        let typ = UOP.getUserName( ) //eslint-disable-line
          .then( ()=>{
            let typ = UOP.getUserID( ) //eslint-disable-line
              .then( ()=>{
                let typ = UOP.getUserModels( this ) //eslint-disable-line
                  .then( ()=>{
                    let typ = UOP.getUserResults( this ) //eslint-disable-line
                      .then( ()=>{
                      }, (err) => { Vue.$log.error('Caught ' + JSON.stringify(err) )});
                  }, (err) => { Vue.$log.error('Caught ' + JSON.stringify(err) )});
              }, (err) => { Vue.$log.error('Caught ' + JSON.stringify(err) )});
          }, (err) => { Vue.$log.error('Caught ' + JSON.stringify(err) )});
      }, (err) => { Vue.$log.error('Caught ' + JSON.stringify(err) )});
  }

  loginNative(){
    let pthis = this;
    UOP.loginNative_this( pthis )
      .then( ()=>{
        // pthis.$log.info('loginNative.then');
        pthis.updateUser();
        pthis.showLoginWarning = false;
      })
      .catch( (err)=>{
        pthis.$log.error('loginNative Caught error:' , err );
        pthis.showLoginWarning = true;
        pthis.loginMessage = err.message;
      });
  }

  loginGoogle(){
    let pthis = this;
    UOP.loginGoogle( pthis )
      .then( ()=> {
        pthis.$log.info('loginGoogle.then');
        pthis.updateUser();
        pthis.showLoginWarning = false;
      })
      .catch( (err)=>{
        pthis.$log.error('loginGoogle Caught error:' + err );
        pthis.showLoginWarning = true;
        pthis.loginMessage = err.message;
      });
  }

  logout(){
    let pthis = this;
    UOP.logout(pthis).then( ()=>{
      pthis.models = [];
      pthis.results = [];
      UOP.getAPIToken().then( ()=>{
        pthis.updateUser();
      }, (err)=>{pthis.$log.error('logout erra:' + JSON.stringify(err)); });
    }, (err)=>{pthis.$log.error('logout errb:' + JSON.stringify(err)); });
  }

}
</script>



