import Vue from 'vue';

let verbose = false;
export const userStore = {
  state: {
    apiToken: '', //if the token is truthy, the user is 'logged in'
    userType: '', //[newb, registered, subscribed]
    userID: '',
    userName: '',
    userEmail: '',
    userModels: [],
    userResults: [],
    useCookies: false,
    showPrivacyTOS: {'show': true, 'dismissedDate': ''}
  },
  getters: {
    getAPIToken( state ){
      // console.log(' returning api token:\n' , state.apiToken );
      if (verbose) console.log(' returning api token:\n' , state );
      return state.apiToken;
    },
    getUserType( state ){
      if (!state.userType)
        return 'newb';
      return state.userType;
    },
    getUserID(state){
      if (!state.userID)
        return false;
      return state.userID;
    },
    getUserName(state){
      if (!state.userName)
        return 'New User';
      return state.userName;
    },
    getUserEmail(state){
      if (!state.userEmail)
        return '';
      return state.userEmail;
    },
    getUserModels(state){
      return state.userModels;
    },
    getUserResults(state){
      return state.userResults;
    },
    showPrivacyTOS(state){
      return state.showPrivacyTOS.show;
    },
    areCookiesAllowed(state){
      return state.useCookies;
    }
  },
  mutations: {
    logOut( state ){
      if (verbose) console.log('logOut');

      state.apiToken = '';
      state.userID = '';
      state.userName = '';
      state.userEmail = '';
      state.userModels = [];
      state.userResults = [];

      this.dispatch( 'modelLogOut' );
      this.dispatch( 'resultLogOut' );
    },
    setUserType( state, type ){
      // console.log('userTypeSetTo: ' + type );
      state.userType = type;
    },
    setUserID(state, newid){
      state.userID = newid;
    },
    setUserName(state, newname){
      state.userName = newname;
    },
    setUserEmail(state, newemail){
      state.userEmail = newemail;
    },
    setAPIToken(state, newtoken ){
      state.apiToken = newtoken;
      Vue.prototype.$axios.defaults.headers.common['Authorization'] = state.apiToken;
    },
    setUserModels(state, models){
      state.userModels = models;
    },
    setUserResults(state, results){
      state.userResults = results;
    },
    allowCookies(state){
      state.useCookies = true;
      state.showPrivacyTOS.show = false;
      state.showPrivacyTOS.date = Date.now();
      // console.log('gtag is ' + Vue.$gtag.enabled );
      Vue.$gtag.enabled = true;
      // console.log('gtag is ' + Vue.$gtag.enabled );
      // Vue.$log.info('allowCookies() = ' + state.showPrivacyTOS.show + ' | ' + state.useCookies );
      Vue.$log.info('Cookies allowed on ' + state.showPrivacyTOS.date);
    },
    disallowCookies(state){
      state.useCookies = false;
      state.showPrivacyTOS.show = false;
      state.showPrivacyTOS.date = Date.now();
      // console.log('gtag is ' + Vue.$gtag.enabled );
      Vue.$gtag.enabled = false;
      // console.log('gtag is ' + Vue.$gtag.enabled );
      // Vue.$log.info('disallowCookies() = ' + state.showPrivacyTOS.show + ' | ' + state.useCookies );
      Vue.$log.info('Cookies disallowed on ' + state.showPrivacyTOS.date);
    }
  },
  actions: {
    setAPIToken({state,commit,rootState}, newtoken ){
      // console.log('action: setApiToken to ' + newtoken);
      state.apiToken = newtoken;
      Vue.prototype.$axios.defaults.headers.common['Authorization'] = state.apiToken;
    }
  }
}

