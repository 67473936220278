<template>
  <span :id="containerName" class="classChartjsContainer">
    <!-- {{containerName}} -->
  </span>
</template>

<style scoped>
.classChartjsContainer{
  position: relative;
  overflow: auto;
  width: 100% !important;
  height: 100% !important;
  /* border: 2px solid yellow; */
}
canvas{
  /* border: 2px solid teal; */
}
</style>

<script type="text/javascript">

import { Component, Watch, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch

export default
@Component({
  components: {},
  props: {
    graph: {},
    show: true
  }
})
class Graph extends Vue {
  containerName = 'containerNameSetOnBeforeMount'; //create a unique name for this graph
  cht; //ChartJS handle

  @Watch('graph') onGraphChanged( newval, oldval ){ /* eslint-disable-line */ //watch for changes in parent data
    // Vue.$log.info('Graph.onGraphChanged()');
    let cnvAna = document.getElementById( this.containerName );
    while( cnvAna.firstChild ){
      cnvAna.removeChild( cnvAna.firstChild );
    }
    cnvAna = null;
    this.paintGraph();
  }

  beforeMount(){
    this.containerName = 'graph' + this.graph.id;
  }

  mounted(){
    if (this.graph && this.graph.id){
      // Vue.$log.info('Graph given graph=' + this.graph.id, this.graph);
      this.paintGraph();
    }
  }

  beforeDestroy(){
    // console.log('Graph.beforeDestroy');
    this.cht = null;
    this.containerName = null;
  }

  paintGraph(){
    // console.log('graph', this.graph);
    if (this.graph == null) {
      Vue.$log.error('Graph.vue: graph is null, cannot paintGraph', this.graph);
      return;
    }
    // this.$log.info('paintGraph()');
    this.cht = null;

    let cnvAna = document.getElementById( this.containerName );
    let cnvPlt = document.createElement('canvas');
    cnvPlt.id = this.graph.datasets[0].plotName;
    cnvPlt.textContent = this.graph.datasets[0].plotName;
    cnvAna.appendChild(cnvPlt);

    let ops = {
      scales: { //beginAtZero does not scale the axes better, can set min: but Math.min(datasets[0].data) is over arrays of {x: y:}..lame-o
        xAxes: [{ type:'linear', position:'bottom', ticks:{beginAtZero:false}, scaleLabel:{ display:true, labelString:this.graph.xlabel.name, fontFamily:'Arial'}}],
        yAxes: [{ type:'linear', position:'left',   ticks:{beginAtZero:false}, scaleLabel:{ display:true, labelString:this.graph.ylabel.name, fontFamily:'Arial'}}]
      },
      legend: { display: false, position: 'right' },
      bezierCurve: false,
      scaleGridColor: 'gray',
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      responsive: true
    };

    let chtOps = { type: 'line', data: this.graph, options: ops };
    this.cht = new Chart(cnvPlt.getContext('2d'), chtOps);/* eslint-disable-line */ //Chart is not imported{} but included via cdn?
    // this.$log.debug('drew chart for graph ' + this.graph.id, this.cht);
    // this.cht.resize();
  }

}

</script>


