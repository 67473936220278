import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue'),
      meta: { title: 'Home' }
    },
    {
      path: '/user/',
      name: 'User',
      component: () => import('./views/User.vue'),
      meta: { title: 'User' }
    },
    {
      path: '/user/registerNative',
      name: 'RegisterNative',
      component: () => import('./views/RegisterNative.vue'),
      meta: { title: 'Register' }
    },
    {
      path: '/model/:modelID?', // ? makes the modelID optional
      name: 'Model',
      component: () => import('./views/Model.vue'),
      meta: { title: 'Model' }
    },
    {
      path: '/about/',
      name: 'About',
      component: () => import('./views/About.vue'),
      meta: { title: 'About' }
    },
    {
      path: '*',
      name: 'Wild',
      component: () => import('./views/Wild.vue')
    }
  ]
})
