



export let blank = `model ModelName

equation

annotation(
  experiment(StartTime=0, StopTime=1, Tolerance=0.01, Interval=0.1),
  __Mechanomy_analyze(plot2D={x:time, y: varName, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}),
);
end modelName; `;

export let formatReference = `model formatReference "this model is a quick reference of standard model formatting"
  //double quotes following a statement provide a documenting comment"
  // double forward slash comments to the end of the line
  /* slash asterisk comments only within */

  parameter Real A "A is a real-valued input parameter"; //parameter denotes A can be changed at runtime
  Real B;

equation
  B = der(A); // assignment order does not matter
  //B = 3; //cannot assign the same variable more than once, leads to more equations than variables to be solved

annotation( // meta comments about the model, comma after each line in annotation
  experiment(StartTime=0, StopTime=1, Tolerance=0.001, Interval=0.1), //how to run the simulation
  __Mechanomy_analyze(plot2D={x:time, y: varName, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}), //see the plotting reference https://composer.mechanomy.com/wiki/index.php/analyze
  __Mechanomy_analyze(plot2D={x:time, y: varName, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}) //no comma on last line
);
end formatReference;`;


export let bouncingBall = `model BouncingBall
  parameter Real eff=0.77 "coefficient of restitution";
  parameter Real grav=9.81 "gravity acceleration";
  Real height(fixed=true, start=1) "height of ball";
  Real vel(fixed=true) "velocity of ball";
  Boolean flying(fixed=true, start=true) "true, if ball is flying";
  Boolean impact;
  Real v_new(fixed=true);
  Integer foo;

equation
  impact = height <= 0.0;
  foo = if impact then 1 else 2;
  der(vel) = if flying then -grav else 0;
  der(height) = vel;

  when {height <= 0.0 and vel <= 0.0,impact} then
    v_new = if edge(impact) then -eff*pre(vel) else 0;
    flying = v_new > 0;
    reinit(vel, v_new);
  end when;

  annotation(
    experiment(StartTime=0, StopTime=1, Tolerance=1e-3, Interval=0.1),
    __Mechanomy_analyze(plot2D={x:time, y: height, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}),
    __Mechanomy_analyze(plot2D={x: time, y: vel, linestyle: solid, linecolor: blue, pointstyle: cross}),
    __Mechanomy_analyze(plot2D={x: time, y: impact, linestyle: none, linecolor: orange, pointstyle: x}),
    __Mechanomy_analyze(plot2D={x: time, y: v_new, linestyle: solid, linewidth: 12, linecolor: orange, pointstyle: x}),
    __Mechanomy_analyze(plot2D={x: time, y: foo, linecolor: orange, pointstyle: circle, pointcolor: blue, pointsize:10}),
    __Mechanomy_analyze(plot2D={x: time, y: der(height), linestyle: solid, linecolor: orange, pointstyle: none}) //no trailing comma!
  );

end BouncingBall;`;


export let simplePendulum = `model SimplePendulum
  inner Modelica.Mechanics.MultiBody.World world;

  Modelica.Mechanics.MultiBody.Joints.Revolute revolute1(n = {0, 0, 1}, phi, useAxisFlange = true);
  Modelica.Mechanics.Rotational.Components.Damper damper2(d = 1);
  Modelica.Mechanics.MultiBody.Parts.Body body1(m = 1, r_CM = {1, 0, 0});

equation
  connect(damper2.flange_b, revolute1.axis);
  connect(revolute1.support, damper2.flange_a);
  connect(revolute1.frame_b, body1.frame_a);
  connect(world.frame_b, revolute1.frame_a);

  annotation(
    experiment(StartTime=0, StopTime=1, Tolerance=1e-3, Interval=0.1),
    __Mechanomy_analyze(plot2D={x:time, y: revolute1.phi, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}),
    __Mechanomy_analyze(plot2D={x:time, y: body1.r_0[2], linestyle: dash, linecolor:red, pointstyle: dot, pointcolor: green}),
    __Mechanomy_analyze(plot2D={x: revolute1.phi, y: body1.frame_a.f[1], linestyle: solid, linecolor:red, pointstyle: dot, pointcolor: blue})
  );
end SimplePendulum;`;

