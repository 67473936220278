import Vue from 'vue';
import store from '@/store/Store'

const PrivacyTOS = {
  template:
    `<div id="PrivacyTOS" style="position:absolute; bottom: 0; z-index:100;">
      <div v-if="show">

        <div class="jumbotron jumbotron-fluid">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-auto justify-content-center">
                <h3>How do we respect our users?</h3>
                <ul>
                  <li>We respect your privacy and record only that information required to use the service, as described in our <a href="http://mechanomy.com/privacypolicy" target="_blank">Privacy Policy</a>.</li>
                  <li>The capabilities of Mechanomy.com and the Model Composer are offered to you according to our <a href="http://mechanomy.com/termsofservice" target="_blank">Terms of Service</a>.</li>
                </ul>
              </div> <!--column -->
            </div> <!--row -->
          </div> <!--container -->

          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-auto justify-content-center">

                <button v-on:click="dismissAllow()" class="btn btn-primary btn-sm align-self-end ">Accept & Dismiss</button>

              </div> <!--column -->
              <div class="col-sm-auto justify-content-center">

                <button v-on:click="dismissNone()" class="btn btn-outline-primary btn-sm">Do Not Use Cookies & Dismiss</button>

              </div> <!--column -->
            </div> <!--row -->
          </div> <!--container -->
        </div> <!--Jumbotron -->

      </div> <!--show -->
    </div>`,
  name: 'PrivacyTOS',
  props: {
  },
  data: function(){
    return{
    }
  },
  computed: {
    show(){
      return store.getters.showPrivacyTOS;
    }
  },
  methods: {
    dismissAllow (){
      Vue.$log.info('PrivacyTOS dismiss&allow clicked');
      store.commit('allowCookies');
    },
    dismissNone (){
      store.commit('disallowCookies' );
    }
  }
}

export default PrivacyTOS;
