// import YAML from 'yaml';

import Vue from 'vue';
// import RJSON from 'relaxed-json';

import * as UTY from '../common/Utility';
import store from '@/store/Store'

let oldAnalyze = null;
let lastResultsAnalyzed = '';
export function makeGraphs(){
  return new Promise( (resolve, reject) => {
    let herestr = 'makeGraphs:';

    //get model syntax
    let text = store.getters.getModelSyntax;
    // Vue.$log.info(herestr + ' given text[' + text +']');

    let valid = store.getters.areResultsValid; //are the results valid
    let newan = lastResultsAnalyzed != store.getters.getResultID; //is the data the same?
    // let chang = hasAnalyzeChanged( toPlot );
    let chang = text != oldAnalyze; //is the analysis syntax the same
    oldAnalyze = text;

    if ( valid && (newan || chang)){
      //parse plot stirng
      // console.log(herestr + 'pre parseAnalyze');
      let toPlot = parseAnalyze( text );
      // console.log(herestr + 'post parseAnalyze toPlot = ', toPlot );

      //lookup toPlot data
      let data = store.getters.getResultData;
      // console.log(herestr+ ' data = ', data );

      let pdata = [];
      for( let it = 0; it < toPlot.length; it++ ){
        // console.log(herestr + ' it ' + it + ' of ' + toPlot.length);

        let pdt = makeDatasetPlot2D( toPlot[it], data );
        if (pdt != false){
          // console.log(it + ' pdt', pdt);
          pdt.id = it+1; //!!!!!!!!!!! v-for requires indexes to start at 1 !!!!!!!!!!!!!!!!!!!!!!!!!
          pdata.push( pdt );
        } else {
          console.log('pdt is false');

        }
        // console.log(herestr + 'pdata now is',pdata);
      }

      resolve( pdata );//don't return a large object to save memory?
    }

    if ( !valid ){
      reject(UTY.promiseRejected('info','Cannot makeGraphs with invalid results'));
    }
    if ( !newan ){
      reject(UTY.promiseRejected('info','Results are the same as plotted, do not need to makeGraphs'));
    }
    if ( !chang ){
      reject(UTY.promiseRejected('info','Analyze has not changed, do not need to makeGraphs'));
    }
  });
}//makeGraphs

function parseAnalyze(modelText) { // take the model text, return toPlot structure
  let herestr = 'parseAnalyze: ';

  store.commit('clearOriginMessages', 'parseAnalyze' ); //clear errors before potentially adding them again
  store.commit('clearOriginMessages', 'makeDatasetPlotBody' ); //clear errors before plotting

  //approach: take string of __Mechanomy_analyze statements, process and remove one line at a time
  //Format is like:
  //__Mechanomy_analyze(plot2D={x:time, y: revolute1.phi, linestyle: dash, linecolor:red,pointstyle: dot, pointcolor: green}),
  let parsedPlot = []; //destination array of parsed plot commands: [over # graphs][#lines per graph]


  let matchPlot2D = [...modelText.matchAll(/^[\s]*__Mechanomy_analyze\s?\(\s*plot2D\s*=\s*(.*)\)/mg)]; // /mg = multiline, global; matchPlot2D is 2D array [line][match object]
  if ( matchPlot2D.length == 0 ){ return []; }
  for (let match of matchPlot2D){ //match[1] is the match: plot2d = ([..]) == [..]
    // parse the json plot commands in each match
    if (match!=null && match.length==2) { //if match is valid, then parse it
      let ret = parseMatchPlot2D( match );
      if (ret != false){
        parsedPlot.push( ret );
      }
    }//if valid
  }//for

  // console.log(herestr + 'parsedPlot', parsedPlot );
  return parsedPlot;
} //parseAnalyze

function parseMatchPlot2D( match ){ //match is a regex match object
  let herestr = 'parseMatchPlot2D: ';

  let spaced = match[1].replace(/ /g, ''); //remove all spaces
  spaced = spaced.replace(/{/g, '{"'); //add "
  spaced = spaced.replace(/}/g, '"}');
  spaced = spaced.replace(/:/g, '": "');
  spaced = spaced.replace(/,/g, '", "');
  // spaced = spaced.replace(/[^\}],/g, '", "');
  // console.log('spaced4',spaced);
  spaced = spaced.replace(/\}", "\{/g, '}, {'); //above makes "}", "{ "x":...  so make it "}, { "x":...
  // console.log('spaced5',spaced);
  let plotparse = {};
  try {
    plotparse = JSON.parse(spaced);
  } catch( err ){
    // Vue.$log.debug('JSON.parse caught error parsing['+spaced+']', err );
    return false; //command probably isn't complete, stop
  }

  let forplot = [];
  if (plotparse.length==null){
    forplot = [plotparse];
  } else {
    forplot = plotparse;
  }
  // console.log(herestr + ' forplot is ', forplot);

  let allvalid = true;
  for (let f of forplot){
    allvalid = allvalid && isPlotArrayValid(f);
    // allvalid = allvalid && isPlotStringValid(f);
  }

  if (allvalid){
    forplot.syntax = match[0];
    return forplot;
  }
  // Vue.$log.debug(herestr + 'not allvalid');
  return false;
} //parseMatchPlot2D

function isPlotArrayValid( json ){
  let ret = false;
  if (json.length != undefined && 0 < json.length){
    for (let j of json){
      ret = ret && isPlotStringValid( j );
    }
  } else {
    ret = isPlotStringValid( json );
  }
  // console.log('isPlotArrayValid:' + ret, json );
  return ret;
}
function isPlotStringValid( json ){ //only checks that keys are valid, should check that options are minimal/consistent
  // console.log('isPlotStringValid:', json );

  //check keys
  let ret = false;
  for (let key of Object.keys( json )){  //get the keys: x, y, height, linestyle, linecolor...
    ret |= (key === 'body');
    ret |= (key === 'x');
    ret |= (key === 'y');

    ret |= (key === 'linestyle') && isLinestyleValid( json[key] );
    ret |= (key === 'linecolor') && isLinecolorValid( json[key] );
    ret |= (key === 'linewidth') && isLinewidthValid( json[key] );

    ret |= (key === 'pointstyle') && isPointstyleValid( json[key] );
    ret |= (key === 'pointcolor') && isPointcolorValid( json[key] );
    ret |= (key === 'pointsize') && isPointsizeValid( json[key] );
    if (!ret){ //return as soon as a key is invalid
      Vue.$log.error('isPlotStringValid: [' + key + '] is not a valid plot definition');
    //   return false;
    }
  }
  return ret;
}
export function isLinestyleValid( sty ){
  let ret = false;
  ret |= sty === 'solid';
  ret |= sty === 'dash';
  ret |= sty === 'none';

  if (!ret){
    Vue.$log.debug( 'isLinestyleValid: [' + sty +'] is not a valid linestyle');
    return false;
  }
  return ret;
}
export function isLinecolorValid( sty ){
  let ret = false;
  ret |= sty === 'red';
  ret |= sty === 'orange';
  ret |= sty === 'green';
  ret |= sty === 'blue';
  ret |= sty === 'black';
  ret |= sty === 'gray';
  ret |= sty === 'white';

  if (!ret){
    // Vue.$log.debug( 'isLinecolorValid: [' + sty +'] is not a valid linecolor');
    return false;
  }
  return ret;
}
export function isLinewidthValid( sty ){
  if ( !isNaN(sty) && typeof(sty) === 'number' ){
    return true;
  }

  let n = parseInt( sty );
  if ( !isNaN(n) && typeof(n) === 'number' ){
    return true;
  }

  Vue.$log.debug( 'isLinewidthValid: [' + sty +'](' + typeof(sty) +') is not a valid linewidth');
  return false;
}

export function isPointstyleValid( sty ){
  let ret = false;
  ret |= sty === 'none';
  ret |= sty === 'circle';
  ret |= sty === 'cross';
  ret |= sty === 'dot';
  ret |= sty === 'rect';
  ret |= sty === 'x';

  if (!ret){
    Vue.$log.debug( 'isPointValid: [' + sty +'] is not a valid pointstyle');
    return false;
  }
  return ret;
}
export function isPointcolorValid( sty ){
  let ret = false;
  ret |= sty === 'red';
  ret |= sty === 'orange';
  ret |= sty === 'green';
  ret |= sty === 'blue';

  if (!ret){
    Vue.$log.debug('isPointcolorValid: [' + sty +'] is not a valid pointcolor');
    return false;
  }
  return ret;
}
export function isPointsizeValid( sty ){
  if ( !isNaN(sty) && typeof(sty) === 'number' ){
    return true;
  }

  let n = parseInt( sty );
  if ( !isNaN(n) && typeof(n) === 'number' ){
    return true;
  }

  Vue.$log.debug('isPointsizeValid: [' + sty +'](' + typeof(sty) +') is not a valid pointsize');
  return false;
}

let oldToPlot = null;
export function hasAnalyzeChanged( newToPlot){
  // Given: plot({x: time, y: height, linestyle: dash, linecolor: red, pointstyle: dot, pointcolor: green});
  // detect changes by separating the toPlot line into its keys, then comparing their arguments

  // console.log('hasAnalyze');
  // console.log(UTY.strDiff( JSON.stringify(oldToPlot), JSON.stringify(newToPlot)));
  let ret = oldToPlot != newToPlot; // true = redo analyze, false = skip
  oldToPlot = newToPlot;
  // console.log('hasAnalyzeChanged: ' + ret );
  return ret;
}//hasAnalyzeChanged





export function makeDatasetPlot2D( parsedPlot, result ){
  //given:
  //   parsedPlot: 0 = x: 'time', y: 'height', linestyle: '', marker: ''...
  //   result: 'height': [], time:[], ...
  // produce an array of objects ready to be plotted:
  //   0 = x: [...height], y: [...time], linestyle, marker, ...

  let herestr = 'makeDatasetPlot2D: ';
  // Vue.$log.debug(herestr + 'start, parsedPlot =', parsedPlot);

  if (parsedPlot[0] == undefined){
    Vue.$log.debug('parsedPlot[0] does not exist', parsedPlot );
    return false;
  }

  let pdata = {};
  pdata.type = 'plotXY'; //vs other kinds of graphs...
  pdata.syntax = parsedPlot.syntax;
  pdata.xlabel = {name: parsedPlot[0].x};
  pdata.ylabel = {name: parsedPlot[0].y};
  pdata.datasets = [];

  // console.log(herestr+'parsedPlot is  ', parsedPlot);
  for (let pp of parsedPlot){

    //determine the plot type
    let typeXY = (pp.x != undefined) && (pp.y != undefined);
    let typeBody = (pp.body != undefined);

    if (typeXY){
      let dat = makeDatasetPlotXY( pp, result );
      pdata.datasets.push( dat );
      // console.log(herestr+'pp made typeXY dat', dat);
    } else if (typeBody){
      makeDatasetPlotBody( pdata, pp, result );
      // console.log(herestr+'pp made typeBody dat' );
    } else {
      Vue.$log.debug(herestr+'parsedPlot is missing a field', pp );
      // return false; //don't return from for!
    }
    // console.log(herestr+'pdata after adding pp', pdata);
  }
  // console.log(herestr+'pdata after adding all', pdata);

  if (pdata.datasets.length == 0){
    return false;
  } else {
    return pdata;
  }

}//makeDatasetPlot2D

function makeDatasetPlotXY( pp, result ){ //pp is the parsed plot syntax, result is the data
  let herestr = 'makeDatasetPlotXY: ';
  let plotName = pp.x + '_vs_' + pp.y;
  let pdata = {plotName: plotName, label: pp.x +' vs ' + pp.y, data: [], borderColor:'rgba(0,255,0,1)', lineTension: 0, borderJoinStyle: 'round', fill:false };


  let indx = getResultIndexOfVariable( result, pp.x );
  let indy = getResultIndexOfVariable( result, pp.y );
  // console.log('found indx' + indx + ' indy' +indy);

  //assign into destination data structure
  if (0 <= indx && 0 <= indy){
    for (let i = 0; i < result[indx].data.length; i++) {
      pdata.data[i] = {
        x: result[indx].data[i],
        y: result[indy].data[i]
      };
    }
  } else {
    if (indx < 0){
      Vue.$log.error(herestr + ' did not find ' + pp.x + ' in result[]');
    }
    if (indy < 0){
      Vue.$log.error(herestr + ' did not find ' + pp.y + ' in result[]');
    }
  }
  // Vue.$log.info(herestr + ' found result data, returning'); console.log('pdata',pdata);
  // console.log('made pdata.datasets['+iseries+'].data', pdata);

  pdata = addStyles(pdata, pp);

  return pdata;
}//makeDatasetPlotXY

function makeDatasetPlotBody( pdata, pp, result ){ //pp is the parsed plot syntax, result is the data; rely on consistent body naming to auto plot parts
  let herestr = 'makeDatasetPlotBody: ';
  let plotName = 'body ' + pp.body;

  //choose which axes to plot
  let varx = 'r_0[1]';
  if (pp.xvar != undefined){
    varx = pp.xvar;
  }
  let vary = 'r_0[2]';
  if (pp.yvar != undefined){
    vary = pp.yvar;
  }
  let sep = '.';
  let framea = 'frame_a';
  let frameb = 'frame_b';
  let nameax = pp.body + sep + framea + sep + varx;
  let nameay = pp.body + sep + framea + sep + vary;
  let namebx = pp.body + sep + frameb + sep + varx;
  let nameby = pp.body + sep + frameb + sep + vary;

  let trace = (pp.trace != undefined && (pp.trace == 'on' || pp.trace == 'true' || pp.trace=='yes') );

  //assign names if they haven't been yet...
  if (pdata.xlabel.name == undefined){
    pdata.xlabel.name = varx; //'x [m]';
    // if name does not contain varx,
    // pdata.xlabel.name = pdata.xlabel.name + ' | ' + varx; //'x [m]';
  }
  if (pdata.ylabel.name == undefined){
    pdata.ylabel.name = vary; //'y [m]';
  }

  //bodies have: bodyName.frame_a.r_0[1], bodyName.frame_b.r_0[2]...
  //need to make arrays in xy of these r_0[i] points
  let ind_ax = getResultIndexOfVariable(result, nameax);
  let ind_ay = getResultIndexOfVariable(result, nameay);
  let ind_bx = getResultIndexOfVariable(result, namebx);
  let ind_by = getResultIndexOfVariable(result, nameby);

  // console.log('found frame_a.x[' + result[ind_ax].name + '] at index ' + ind_ax ); console.log('found frame_a.y[' + result[ind_ay].name + '] at index ' + ind_ay ); console.log('found frame_b.x[' + result[ind_bx].name + '] at index ' + ind_bx ); console.log('found frame_b.y[' + result[ind_by].name + '] at index ' + ind_by );
  if ( ind_ax < 0 ){ //did not find frame_a
    let msg = herestr + "did not find " + nameax + " in results, can't plot";
    store.commit('addErrorMessage', {origin:'makeDatasetPlotBody', message: msg });
  }
  if ( ind_ay < 0 ){
    let msg = herestr + "did not find " + nameay + " in results, can't plot";
    store.commit('addErrorMessage', {origin:'makeDatasetPlotBody', message: msg });
  }
  if ( ind_bx < 0 ){
    let msg = herestr + "did not find " + namebx + " in results, can't plot";
    store.commit('addErrorMessage', {origin:'makeDatasetPlotBody', message: msg });
  }
  if ( ind_bx < 0 ){
    let msg = herestr + "did not find " + nameby + " in results, can't plot";
    store.commit('addErrorMessage', {origin:'makeDatasetPlotBody', message: msg });
  }

  //assign into destination data structure; since we want to draw multiple lines, need multiple datasets
  for (let it = 0; it < result[0].data.length; it++) { //it is over time
    let dat = {plotName: plotName, label: plotName, data: [], borderColor:'rgba(255,0,0,1)', lineTension: 0, borderJoinStyle: 'round', fill:false };
    dat = addStyles(dat, pp);
    // console.log('pdata it'+it, dat);

    //make body shape --- this needs to be expanded to handle variable #frames & look nicer
    dat.borderWidth = 20; //scale the body width as 1/5th its length
    dat.borderColor = dat.borderColor.replace(/,\d*\)/, ',0.3)'); //make bodies transparent so their overlap is legible

    if (0 < ind_ax && 0 < ind_ay){
      dat.data.push({x:result[ind_ax].data[it], y:result[ind_ay].data[it]});
    }
    if (0 < ind_bx && 0 < ind_by){
      dat.data.push({x:result[ind_bx].data[it], y:result[ind_by].data[it]});
    }
    pdata.datasets.push( dat ); //every instance is another line
  }

  if (trace){
    Vue.$log.info('tracing frames');
    if (0 < ind_ax && 0 < ind_ay){
      let dat = {plotName: 'trace_' + framea, label: 'trace_'+framea, data: [], borderColor:'rgba(255,0,0,1)', borderJoinStyle: 'round', fill:false };
      dat = addStyles(dat, pp);
      for (let it = 0; it < result[0].data.length; it++) { //it is over time
        dat.borderColor = dat.borderColor.replace(/,\d*\)/, ',0.3)'); //make bodies transparent so their overlap is legible
        dat.data.push({x:result[ind_ax].data[it], y:result[ind_ay].data[it]});
      }
      pdata.datasets.push( dat );
    }
    if (0 < ind_bx && 0 < ind_by){
      let dat = {plotName: 'trace_' + frameb, label: 'trace_'+frameb, data: [], borderColor:'rgba(255,0,0,1)', borderJoinStyle: 'round', fill:false };
      dat = addStyles(dat, pp);
      for (let it = 0; it < result[0].data.length; it++) { //it is over time
        dat.borderColor = dat.borderColor.replace(/,\d*\)/, ',0.3)'); //make bodies transparent so their overlap is legible
        dat.data.push({x:result[ind_bx].data[it], y:result[ind_by].data[it]});
      }
      pdata.datasets.push( dat );
    }

  }//trace

  // Vue.$log.info(herestr+'made pdata', pdata );

  return pdata;
}//makeDatasetPlotXY

function getResultIndexOfVariable( result, varname ){
  for (let ind = 0; ind < result.length; ind++){
    if (result[ind].name == varname){
      // Vue.$log.debug('found '+varname+' at index['+ind+'] = ' + result[ind].name );
      return ind;
    }
  }
  Vue.$log.debug('getResultIndexOfVariable() did not find '+varname+' in results');
  return -1;
}

function addStyles( dset, pp ){ //
  //lines
  if ('linecolor' in pp) {
    dset.borderColor = colorNameToRGBA( pp.linecolor);
  }
  if ('linestyle' in pp && pp.linestyle == 'dash') {
    dset.borderDash = [5, 15, 25];
  }
  if ('linestyle' in pp && pp.linestyle == 'none') {
    dset.showLine = false;
  }
  if ('linewidth' in pp) {
    dset.borderWidth = pp.linewidth;
  }

  //points
  if ('pointcolor' in pp) {
    dset.pointBorderColor = colorNameToRGBA( pp.pointcolor);
  }
  if ('pointcolor' in pp) {
    dset.pointBackgroundColor = colorNameToRGBA( pp.pointcolor);
  }
  if ('pointstyle' in pp) {
    dset.pointStyle = pp.pointstyle;
  }
  if ( 'pointstyle' in pp && pp.pointstyle == 'none') {
    dset.radius = 0;
  }
  if ('pointsize' in pp) {
    dset.radius = pp.pointsize;
  }

  return dset;
}

function colorNameToRGBA(colorName) {
  if (colorName == 'red') return 'rgba(255,0,0,1)';
  if (colorName == 'green') return 'rgba(0,255,0,1)';
  if (colorName == 'blue') return 'rgba(0,0,255,1)';

  if (colorName == 'yellow') return 'rgba(255,255,0,1)';
  if (colorName == 'cyan') return 'rgba(0,255,255,1)';
  if (colorName == 'magenta') return 'rgba(255,0,255,1)';

  if (colorName == 'orange') return 'rgba(255,127,0,1)';

  if (colorName == 'black') return 'rgba(0,0,0,1)';
  if (colorName == 'white') return 'rgba(255,255,255,1)';
  if (colorName == 'gray') return 'rgba(127,127,127,1)';

  return 'rgba(0,0,0,1)'; //default return

}



