<template>
 <span id="PublicModelInfo">
    <span v-if="show">
      <button v-on:click="openModel()" class="btn btn-outline-primary btn-lg"><code>{{ model.modelName }}</code></button>
    </span>
  </span>
</template>


<script type="text/javascript">

import {Vue, Component} from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch

export default
@Component({
  components: {},
  props: {
    model: {}
  }
})
class PublicModelInfo extends Vue {
  show = true;

  openModel(){
    this.$router.push({ name: 'Model', params:{ modelID: this.model.modelID}});
  }
}//class

</script>


<style scoped>
#PublicModelInfo{
  margin-left: 1em;
}

</style>


