import Vue from 'vue';

export const uiStore = {
  state: {
    infoMessages: [],
    warningMessages: [],
    errorMessages: [],
    isServerDown: false
  },

  getters: {
    getInfoMessages(state){
      return state.warningMessages;
    },
    getWarningMessages(state){
      return state.warningMessages;
    },
    getErrorMessages(state){
      return state.errorMessages;
    },
    getIsServerDown( state ){
      // Vue.$log.info('uiStore.getIsServerDown: ' + state.isServerDown, state);
      return state.isServerDown;
    }
  },
  mutations: {
    addInfoMessage( state, infoObject ){
      let incld = false;
      for (let io of state.infoMessages){
        if ( io.message == infoObject.message ){
          incld = true;
          break;
        }
      }
      // console.log('incld',incld);
      if (!incld){
        // Vue.$log.info( 'adding error message:', errorObject.message );
        state.infoMessages.push( {origin: infoObject.origin, message: infoObject.message} );
      }
    },

    // addWarningMessage( state, warningMessage ){
    //   // Vue.$log.info( 'adding warning message:', warningMessage );
    //   state.warningMessages.push( warningMessage );
    // },
    addErrorMessage( state, errorObject ){
      let incld = false;
      for (let eo of state.errorMessages){
        if ( eo.message == errorObject.message ){
          incld = true;
          break;
        }
      }
      // console.log('incld',incld);

      if (!incld){
        // Vue.$log.info( 'adding error message:', errorObject.message );
        state.errorMessages.push( {origin: errorObject.origin, message: errorObject.message} );
      }
    },

    setIsServerDown( state, isServerDown ){
      // Vue.$log.info('uiStore.setIsServerDown: ' + isServerDown);
      state.isServerDown = isServerDown;
    },
    clearInfoMessages( state ){
      state.infoMessages = [];
    },
    clearWarningMessages( state ){
      state.warningMessages = [];
    },
    clearErrorMessages( state ){
      state.errorMessages = [];
    },

    clearOriginMessages( state, origin ){ //clear only those issued by function name: [isModelSyntaxValid, ]
      state.infoMessages = state.infoMessages.filter( (obj)=>{
        // Vue.$log.debug('clearOriginMessages: ' + obj.origin + ' vs ' + origin );
        return (obj.origin != origin);
      });
      state.warningMessages = state.warningMessages.filter( (obj)=>{
        // Vue.$log.debug('clearOriginMessages: ' + obj.origin + ' vs ' + origin );
        return (obj.origin != origin);
      });
      state.errorMessages = state.errorMessages.filter( (obj)=>{
        // Vue.$log.debug('clearOriginMessages: ' + obj.origin + ' vs ' + origin );
        return (obj.origin != origin);
      });
    }
  }
}


