<template>
  <div id="registerNative">
      <div class="row mt-5">
        <div class="col-md-6 m-auto">
          <div class="card card-body">
            <h1 class="text-center mb-3"> <i class="fas fa-user-plus"></i> Register </h1>
            <form v-on:submit.prevent="validateForm">
              <!-- submit.prevent to prevent submit from reloading the page -->
              <div class="form-group">
                <!-- <label for="name">Name &#38;nbsp; <i v&#45;if="missingName">user name is required</i></label> -->
                <input type="name" id="name" name="name" class="form-control" placeholder="Enter Name" v-model="user.userName"> <!-- in value=, ejs is there to retain the name if the validation fails..-->
                <i v-if="missingName">user name is required</i>
              </div>
              <div class="form-group">
                <!-- <label for="email">Email &#38;nbsp; <i v&#45;if="missingEmail">email is required</i></label> -->
                <input type="email" id="email" name="email" class="form-control" placeholder="Enter Email" v-model="user.email">
                <i v-if="missingEmail">email is required</i>
              </div>
              <div class="form-group">
                <!-- <label for="password">Password &#38;nbsp; <i v&#45;if="missingPW">password is required</i><i v&#45;if="pwTooShort">&#38;nbsp; 8+ characters</i></label> -->
                <input type="password" id="password" name="password" class="form-control" placeholder="Create Password" v-model="user.passA">
                <i v-if="missingPW">password is required</i><i v-if="pwTooShort"> &nbsp; 8+ characters</i>
              </div>
              <div class="form-group">
                <!-- <label for="password2">Confirm Password &#38;nbsp; <i v&#45;if="pwDontMatch">passwords don't match</i></label> -->
                <input type="password" id="password2" name="password2" class="form-control" placeholder="Confirm Password" v-model="user.passB">
                <i v-if="pwDontMatch">passwords don't match</i>
              </div>
              <button type="submit" class="btn btn-primary btn-block" v-if="showRegister"> Register </button>
            </form>
            <i v-if="showMessage">{{registerMessage}}</i>
            <router-link :to="{name: 'User'}" v-if="showLogin">Please log in</router-link>
            <router-link :to="{name: 'User'}" v-if="!showLogin">Have an account? Please log in</router-link>
          </div>
        </div>
      </div>

  </div>
</template>

<script type="text/javascript">

// const bcrypt = require('bcryptjs');
import bcrypt from 'bcryptjs'

export default {
  name: 'RegisterNative',
  metaInfo:{
    title: 'Mechanomy WebEnv'
  },
  data(){
    return{
      user: {
        userName: '',
          email: '',
          passA: '',
          passB: ''
      },
      showRegister: false,
      missingName: true,
      missingEmail: true,
      missingPW: true,
      pwTooShort: true,
      pwDontMatch: true,
      showLogin: false,
      showMessage: false,
      registerMessage: ''
    }
  },
  components: {
  },
  mounted(){
    this.$log.debug('Register.vue mounted()');
  },
  beforeUpdate(){
    this.missingName = this.user.userName == '';
    this.missingEmail = this.user.email == '';
    this.missingPW = this.user.passA == '';
    this.pwTooShort = this.user.passA.length < 8;
    this.pwDontMatch = this.user.passA != this.user.passB;
    this.showRegister = !( this.missingName || this.missingEmail || this.missingPW || this.pwTooShort || this.pwDontMatch );
  },
  methods:{
    validateForm: function () {
      this.$log.debug('Register.vue validateForm()');
      let pthis = this;

      let usr = {name: pthis.user.userName, email: pthis.user.email.toLowerCase(), phash: ''};

      //hash password client side
      bcrypt.genSalt(10, (err1, salt)=> bcrypt.hash(pthis.user.passA, salt, (err2, hash)=>{
        if(err2) throw err2;
        usr.phash = hash;
        pthis.$log.debug('registering usr', usr);

        pthis.$axios.post( '/api/register/native', usr )
          .then(function(res){
            if (res.data.message == 'user now registered'){
              pthis.showLogin = true;
              pthis.showMessage = true;
              pthis.registerMessage = res.data.message;
            }else if (res.data.message == 'email is already registered'){
              pthis.showLogin = true;
              pthis.showMessage = true;
              pthis.registerMessage = res.data.message;
            }else { //had some other error
              pthis.registerError = true;
              pthis.registerMessage = res.data.message;
            }
          })
          .catch( (err) => {
            pthis.$log.debug('apiRegisterNative caught error',err);
          });
      }));
    } //validateForm

  }//methods
}
</script>


<style scoped>
</style>
