<template>
  <div id="resultInfo">
    <div id="ifShow" v-if="show">
      <button v-on:click="openResult()" class="btn btn-outline-primary btn-sm"> {{ result.modelName }} ran on {{ dateRan }} </button>
      <button v-on:click="deleteResult()" class="btn btn-outline-primary btn-sm"><code>X</code></button>

      <div id="ifExpanded" v-if="expanded" class="border rounded-sm">
          <button v-on:click="downloadCSV()" class="btn btn-outline-primary btn-sm">Download CSV</button>

          <div id="ifMoreThanTwenty" v-if="moreThanTwenty">
            <b>Variables:</b>
            <ul id="varNames">
              <li v-for="name of resultVarNames" v-bind:key="name">
                {{ name }}
              </li>
            </ul>
          </div>
          <span v-else>
            <b> Variables: </b><span v-for="name of resultVarNames" v-bind:key="name">{{name}} </span>
          </span>

      </div> <!--expanded-->
    </div> <!--show-->
  </div>
</template>

<style scoped>
#resultInfo{
  overflow: hidden;
  vertical-align:middle;
  /* border: 1px solid green; */
}
#ifExpanded{
  /* position: relative; */
  /* background-color: #ff999922; */
  max-height: 500px; /* controls the infoArea sizing, probably need flexbox to get this to be the same as the model height */
  /* border: 1px solid yellow; */
  overflow-y: scroll;
}
li{
  font-size:0.9em;
}
 </style>

<script type="text/javascript">

import { Component, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch
import fileDownload from 'js-file-download';

import * as MEH from '@/common/ModelEditorHelper';
import * as UTY from '@/common/Utility';
import store from '@/store/Store'

export default
@Component({
  components: {},
  props: {
    result: { //really a results meta data object, does not have data..
      type: Object,
      required: true
    }
  }
})
class ResultInfo extends Vue {
  show = true; //show the result, used to hide the result when deleted
  expanded = false; //whether to expand the result info box
  resultVarNames = []; //list of result variable names
  csv = '';
  moreThanTwenty = true;
  dateRan = ''; //string for the formatted date created

  mounted(){
    let dt = new Date( this.result.dateRan);
    this.dateRan = dt.toLocaleDateString({year:'2-digit', month:'2-digit'});

    if (this.$route.name == 'Model' ){ //only auto-expand result if on the ModelEditor
      this.expanded = true;
      setTimeout( ()=>{ this.downloadResult() }, 500); //this delay seems to allow the graphs to finish drawing before upsetting their sizing..
    }

    setTimeout( ()=>{this.sizeInfoToCodem();}, 100 ); // this works for first load, but really want this as a watch on ifExpanded, in case the user clicks on it...
  }

  beforeDestroy(){ //does this help?
    this.csv = null;
  }

  openResult( ) {
    this.expanded = !this.expanded;
    if (this.expanded){
      this.downloadResult();
    }
  }//openResult

  downloadCSV() {
    let name = `${this.result.modelName}_${this.result.resultID}.csv`;
    try{
      fileDownload( this.csv, name ); //fileDownload kills the console log...
    } catch (err) {
      Vue.$log.warn('Caught error downloading csv:', err );
    }
  }

  deleteResult(){
    this.show = false;
    deleteResult( this.result.resultID );
  }//deleteResult

  downloadResult(){
    this.resultVarNames = [];
    this.csv = '';

    if (this.resultVarNames.length == 0){
      MEH.retrieveResultID( this.result.resultID )
        .then( () => {
          let res = store.getters.getResultData;
          // Vue.$log.debug('store.getters.getResultData:', res );

          let delim = ',';
          let ncols = res.length;
          // this.moreThanTwenty = 20 < ncols;
          let nrows = res[0].data.length;
          for (let irow = 0; irow < nrows; irow++){
            let row = '';
            for (let icol = 0; icol < ncols; icol++){

              if (irow == 0){ //var names on the first row
                row = row + res[icol].name;
                this.resultVarNames.push( res[icol].name );
              } else {
                row = row + res[icol].data[irow];
              }
              if (icol < ncols -1){ //omit comma on last column
                row = row + delim;
              }
            }
            this.csv = this.csv + row + '\n';
          }
        }, (err) => {
          Vue.$log.error('downloadResult caught1:', err );
        })
        .catch( (err) =>{
          Vue.$log.error('downloadResult caught2:', err );
        });
    }
  }//downloadResult

  sizeInfoToCodem(){
    if (this.show && this.expanded){
      let dcodem = window.getComputedStyle(document.getElementById("codemArea"));
      let dexpan = document.querySelector('#ifExpanded');
      dexpan.style.maxHeight = '' + (parseInt(dcodem.height) - 150) + 'px';
    }
  }
}

export function deleteResult( resid ){
  Vue.$log.debug('deleteResult', resid);

  return new Promise( (resolve,reject) => {
    Vue.prototype.$axios.delete('/api/result/remove/' + resid)
      .then( (res) => {
        Vue.$log.debug('apiResultRemove response', res);
        resolve( true );
      })
      .catch( err => {
        Vue.$log.warn('apiResultRemove caught err', err);
        reject( UTY.promiseRejected('error', 'apiResultRemove caught error: ' + err ));
      });//apimodelget
  });
}//deleteModel


</script>


