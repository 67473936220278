
export let BannedModelicaCommands = [
  //this list is run pre-pended to '[\\s]*\([\\s]*.*[\\s]*.*[\\s]*\).*[\\s.]*;';
  //to screen for the pattern: command_(...)_;, where _ can be whitespace and newline

  // Modelica.Utilities.Files: https://build.openmodelica.org/Documentation/Modelica.Utilities.Files.html
  'list',
  'copy',
  'move',
  'remove',
  'removeFile',
  'createDirectory',
  'exist',
  'assertNew',
  'fullPathName',
  'splitPathName',
  'temporaryFileName',
  'loadResource',

  // Modelica.Utilities.Streams: https://build.openmodelica.org/Documentation/Modelica.Utilities.Streams.html
  // 'print',
  'readFile',
  'readLine',
  'countLines',
  // 'error',
  'close',
  'readMatrixSize',
  'readRealMatrix',
  'writeRealMatrix',

  // Modelica.Utilities.System: https://build.openmodelica.org/Documentation/Modelica.Utilities.System.html
  'getWorkDirectory',
  'setWorkDirectory',
  'getEnvironmentVariable',
  'setEnvironmentVariable',
  'getPid',
  'command',
  'exit',

  // Modelica.Utilities.Types: https://build.openmodelica.org/Documentation/Modelica.Utilities.Types.html
  'FileType',

  // Modelica.Utilities.Internal: https://build.openmodelica.org/Documentation/Modelica.Utilities.Internal.html
  'ExternalReferences',
  'PartialLoadResource',
  'PartialModelicaServices',
  'exitBase',


  'FileSystem',
  'mkdir',
  'rmdir',
  'stat',
  'rename',
  'removeFile',
  'copyFile',
  'readDirectory',
  'getNumberOfFiles',

];
