<template>
  <header class="header">
      <div id="nav">
        <!-- <router&#45;link to="/"><a style="font&#45;family: Aldrich, Mono; color: var(&#45;&#45;mechanomyColor);">Mechanomy</a></router&#45;link>/ -->
        <a href="http://mechanomy.com" style="font-family: Aldrich, Mono; color: var(--mechanomyBlue);">Mechanomy</a> /
        <!-- <router&#45;link :to="{name: 'About'}" style="color: red;"><i>&#38;alpha;</i></router&#45;link> / -->
        <router-link :to="{name: 'Home'}">Composer</router-link> /
        <router-link :to="{name: 'User'}">{{getStoreUserName}}</router-link> /
        <router-link :to="{name: 'Model'}">{{getStoreModelName}}</router-link>
        <!-- / Unsaved -->
        <!-- show unsaved / revision status if logged in -->

        <span class="aboutVersion">
          <router-link :to="{name: 'About'}" ><i>&alpha; {{version}}</i></router-link>
        </span>
      </div>

      <div id="serverError" v-if="show">
      <!-- <div id="serverError" v&#45;if="getIsServerDown"> -->
          <div class="alert alert-warning" role="alert">{{ serverErrorMessage }} </div>
      </div>

  </header>
</template>
<style scoped>
.header {
  background-color: #eee;
  color: #bbb;
  text-align: left;
  padding: 0.4em; /* give a little space from the page edge */
  position: relative;
}
.header a {
  color: #111;
  padding-right: 5px;
  text-decoration: none;
}
.aboutVersion{
  float: right;
}
.aboutVersion i{
  color: red;
  align: right;
}

</style>

<script type="text/javascript">

import { Component, Watch, Vue } from 'vue-property-decorator';
import store from '@/store/Store'

export default
@Component({
  components: {}
})
class Header extends Vue {
  show = true;
  serverErrorMessage = 'Apologies, the Composer server is being updated, please check back in an hour.';
  version = '';

  //computed:
  get getIsServerDown(){
    let ret = store.getters.getIsServerDown;
    Vue.$log.info('getIsServerDown returned ' + ret);
    return ret;
  }
  @Watch('getIsServerDown') onResultChange( newv, oldv ){ /* eslint-disable-line */
    Vue.$log.info('watch re', newv);
    this.show = newv;
  }

  get getStoreUserName() {
    let nm = store.getters.getUserName;
    if (!nm){
      nm = 'Anonymous';
    }
    return nm;
  }

  get getStoreModelName() {
    let mn = store.getters.getModelName;
    if (!mn)
      mn = 'New Model';
    return mn;
  }
  mounted(){
    this.show = store.getters.isServerDown;
  }
}



</script>


