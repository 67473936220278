<template>
  <div id="ModelInfo">
    <div v-if="show">
      <button v-on:click="expandInfo()" class="btn btn-outline-primary btn-sm"><code>{{ model.modelName }}</code></button>
      <span> created {{ dateCreated }}</span>
      <div id="ifExpanded" v-if="expanded" class="border rounded-sm">
        &#8627;
        <button v-on:click="openModel()" class="btn btn-outline-primary btn-sm" title="Open this model in the editor"><code>Open</code></button>
        &nbsp;
        <button v-on:click="makePublic()" class="btn btn-outline-primary btn-sm" title="Make this model viewable to the public"><code>Make public</code></button>
        &nbsp;
        <button v-on:click="copyLink()" class="btn btn-outline-primary btn-sm" title="Place a link to this model onto your clipboard"><code>Copy link</code></button>
        &nbsp;
        <button v-on:click="deleteModel()" class="btn btn-outline-primary btn-sm" title="Delete this model"><code>Delete</code></button>
        &nbsp;
        <span id="ifMessage" v-if="showMessage" >
          {{messageText}}
        </span>
      </div>
    </div> <!--show-->
  </div>
</template>

<style scoped>
#ModelInfo{
  position: relative;
  z-index: 0;
  /* background-color: #ff999922; */
  /* border: 1px solid black; */
}
</style>


<script type="text/javascript">

import { Component, Watch, Vue } from 'vue-property-decorator'; //https://github.com/kaorun343/vue-property-decorator#Watch
import fileDownload from 'js-file-download';
import copy from 'copy-to-clipboard';

import * as MEH from '@/common/ModelEditorHelper';
import * as UTY from '@/common/Utility';
import store from '@/store/Store'

export default
@Component({
  components: {},
  props: {
    model: {
      type: Object,
      required: true
    }
  }
})
class ModelInfo extends Vue {
  show = true; //show the modelInfo, used to hide the model when deleted
  expanded = false; //whether to expand the result info box
  showMessage = false; //whether to expand the result info box
  messageText = '';
  dateCreated = ''; //string for the formatted date created

  mounted(){
    let dt = new Date( this.model.dateCreated);
    this.dateCreated = dt.toLocaleDateString({year:'2-digit', month:'2-digit'});
  }

  expandInfo(){
    this.expanded = !this.expanded;
  }

  openModel(){
    Vue.$log.info('openModel( ' + this.model.modelID +' )' )
    this.$router.push({ name: 'Model', params:{ modelID: this.model.modelID}});
  }

  makePublic(){
    let publicLicenseName = 'MIT';
    // console.log(this.model);
    if( window.confirm( 'Making public will allow any user to view, edit, and run [' + this.model.modelName + "]. This will necessarily change the model's license from [" + this.model.license + '] to [' + publicLicenseName +']. Proceed?')){
      let dt = new Date();
      Vue.$log.info('makePublic( ' + this.model.modelID +' ) confirmed on ' + dt.toLocaleDateString({year:'2-digit', month:'2-digit'}));
      makePublic(this.model.modelID);
      this.model.access = 'public'; //reset this here to avoid re-requesting user's models

      if (this.showMessage){
        this.showMessage = false;
        this.messageText = '';
      }
      return true;
    }
    return false;
  }

  copyLink(){
    Vue.$log.debug('copyLink(', this.model.modelID);
    if (this.model.access == 'public'){
      let link = new URL('#/model/'+this.model.modelID, 'https://composer.mechanomy.com/' );
      Vue.$log.info('made url[' + link + ']');
      copy(link);
    } else {
      this.showMessage = true;
      let msg ='Model must be public to create a link.';
      this.messageText = msg;
      // store.commit('addInfoMessage', {origin: 'copyLink', message:msg } );
      Vue.$log.info(msg);
    }
  }

  deleteModel(){
    // console.log(this.model);
    if( window.confirm( 'Delete model ' + this.model.modelName + '?' )){
      deleteModel(this.model.modelID);
      this.show = false;
    }
  }
}

export function makePublic( mdlid ){
  Vue.$log.debug('makePublic(', mdlid);

  return new Promise( (resolve,reject) => {
    Vue.prototype.$axios.post('/api/model/makePublic/' + mdlid)
      .then( (res) => {
        Vue.$log.debug('apiModelMakePublic response', res);
        resolve( true );
      })
      .catch( err => {
        Vue.$log.error('apiModeMakePublic caught err', err);
        reject( UTY.promiseRejected('error', 'apiModeMakePublic caught error: ' + err ));
      });//apimodelget
  });
}//makePublic

export function deleteModel( mdlid ){
  Vue.$log.debug('deleteModel', mdlid);

  return new Promise( (resolve,reject) => {
    Vue.prototype.$axios.delete('/api/model/remove/' + mdlid)
      .then( (res) => {
        Vue.$log.debug('apiModelRemove response', res);
        resolve( true );
      })
      .catch( err => {
        Vue.$log.error('apiModelRemove caught err', err);
        reject( UTY.promiseRejected('error', 'apiModelRemove caught error: ' + err ));
      });//apimodelget
  });
}//deleteModel

</script>


