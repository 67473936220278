import Vue from 'vue';
import {BannedModelicaCommands} from '@/common/BannedModelicaCommands';

import store from '@/store/Store';
import * as UTY from '@/common/Utility';

export function isModelSyntaxValid() {
  // Vue.$log.debug('isModelSyntaxValid:');

  // store.commit('clearOriginMessages',  'isModelSyntaxValid' );

  let syntax = store.getters.getModelSyntax;

  return new Promise((resolve,reject) => {

    store.commit('clearOriginMessages',  'isModelSyntaxValid' ); //clear errors before potentially adding them again

    let ban = includesBanned( syntax );
    if (ban.includesBanned){
      let msg = 'Model is not valid because it includes the illegal term [' + ban.term +']';
      reject({severity: 'info', message: msg});
    }

    let modelName = findModelName( syntax )
    if (!modelName.valid){
      reject({severity: 'info', message: modelName.message});
    }
    let modelEnd = findModelEnd( syntax );
    if (!modelEnd.valid){
      reject({severity: 'info', message: modelEnd.message});
    }
    if (modelName.modelName != modelEnd.modelName){
      let msg = "Model names do not match, first line is [" + modelName.modelName + "] while final line is [" + modelEnd.modelName+']';
      store.commit('addErrorMessage', {origin: 'isModelSyntaxValid', message: msg} );
      reject({severity: 'info', message: msg});
    }

    let multi = checkMultilineComment( syntax );
    if (!multi.valid){
      reject({severity: 'info', message: multi.message});
    }

    if( multi.valid ){
      let semi = checkModelSemicolon( syntax );
      if (!semi.valid){
        reject({severity: 'info', message: semi.message});
      }
    }

    let comma = checkModelAnnotationTrailingComma( syntax );
    if (!comma.valid) {
      reject({severity: 'info', message: comma.message});
    }

    resolve( true );
  });
}


function includesBanned( syntax ){ // F = does not include banned
  store.commit('clearOriginMessages',  'includesBanned' ); //clear errors before potentially adding them again
  let reg = '[\\s]*\\([\\s]*.*[\\s]*.*[\\s]*\\)+[\\s.]*;'; //multiline regex...unescaped \ can create SyntaxError: "nothing to repeat"

  let includesBanned = false;
  let w = '';
  for (w of BannedModelicaCommands){
    if (syntax.includes(w)){

      let mat = syntax.match( new RegExp( w + reg, 'g' ));
      if (mat){
        Vue.$log.warn('BannedModelicaCommand [' + w + '] is in syntax');
        // console.log('>>' + w, mat );
        includesBanned = true;
        store.commit('addErrorMessage', {origin: 'includesBanned', message: 'Model is not valid because it includes the illegal term [' + w +']'} );
        break;
      }
    }
  }
  return {includesBanned: includesBanned, term: w};
}

export function findModel( text ){
  let mn = findModelName( text );
  // console.log(mn);
  let me = findModelEnd( text );
  // console.log(me);

  let modelText = text.substring( mn.indexStart, me.indexStop );
  // console.log('modelText=', modelText);

  return modelText;
}
export function findModelName( text ){
  store.commit('clearOriginMessages',  'findModelName' ); //clear errors before potentially adding them again

  let mat = matchModelName( text );

  if (!mat || mat.length < 2){
    let ret = {valid: false, modelName: "unnamed", message:"ModelName not found on the first line"}
    Vue.$log.warn( ret );
    store.commit('addErrorMessage', {origin: 'findModelName', message: 'ModelName not found on the first line, format should be:\nmodel modelname\n ...declarations...\nequation\n ...equations...\nannotation(...);\nend modelname;'});
    return ret;
  }

  let ret = {valid: true, modelName: mat[1], message:"ModelName found"}
  // Vue.$log.info( ret );
  return ret;
}

function matchModelName( text ){
  let mat = matchModelName_A(text);
  // Vue.$log.info('matchModelName_A mat=', mat );
  if (!mat) {
    mat = matchModelName_B(text);
    // Vue.$log.info('matchModelName_B mat=', mat );
  }
  if (!mat) {
    mat = matchModelName_C(text);
    // Vue.$log.info('matchModelName_C mat=', mat );
  }
  if (!mat) {
    mat = matchModelName_D(text);
    // Vue.$log.info('matchModelName_D mat=', mat );
  }
  if (!mat) {
    mat = matchModelName_E(text);
    // Vue.$log.info('matchModelName_E mat=', mat );
  }

  if (mat){
    mat.indexAfter = mat.index + mat[0].length;
  }
  return mat;
}
function matchModelName_A( text ){
  //                                 1=model name
  let mat = text.match( /\s*model[ \t]+([A-z0-9-\_]+)/ ); //
  return mat;
}
function matchModelName_B( text ){
  if (!text || text == ''){
    text = `model Bouncing-Ball33 "a bouncy3.3 ball" //this is thefirst 0z0.3z line`
  }
  //                                 1=model name, 2=description, 3=comment
  let mat = text.match( /\s*model\s*([A-z0-9-]+)\s*"(.*)"\s*\/\/(.*)/ );
  // console.log('B', mat );
  return mat;
}
function matchModelName_C( text ){
  if (!text || text == ''){
    text = `model Bouncing-Ball33 "a bouncy ball" /*this is thefirst line*/`
  }
  //                                 1=model name, 2=description, 3=comment
  let mat = text.match( /\s*model\s*([A-z0-9-]+)\s*"(.*)"\s*\/\*(.*)\*\// );
  // console.log('C', mat );
  return mat;
}
function matchModelName_D( text ){
  if (!text || text == ''){
    text = `model Bouncing-Ball33 "a bouncy ball" `
  }
  //                                 1=model name, 2=description, 3=comment
  let mat = text.match( /\s*model\s*([A-z0-9-]+)\s*"(.*)"\s*/ );
  // console.log('D', mat );
  return mat;
}
function matchModelName_E( text ){
  if (!text || text == ''){
    text = `model Bouncing-Ball33 //this is thefirst line`
  }
  //                                 1=model name, 2=description, 3=comment
  let mat = text.match( /\s*model\s*([A-z0-9-]+)\s*\/\/(.*)/ );
  // console.log('E', mat );
  return mat;
}

function findModelEnd( text ){
  store.commit('clearOriginMessages',  'findModelEnd' ); //clear errors before potentially adding them again
  let mat = [...text.matchAll(/end[ \t]*([A-z0-9-\_]+)[ \t]*;/g)];
  // Vue.$log.info('findModelEnd mat', mat );

  if (mat && 0 < mat.length){ //array of matches
    if (mat[mat.length-1].length == 2){ //end ModelName is the last end in the model
      let name = mat[mat.length-1][1];

      let ret = {valid: true, modelName: name, message:"found: end ModelName;"}
      // Vue.$log.info( ret );
      return ret;
    }
  }
  let ret = {valid: false, modelName: "unnamed", message:"ModelName not found after end, format should be:\nmodel ModelName\n ...declarations...\nequation\n ...equations...\nannotation(...);\nend ModelName;"}
  Vue.$log.warn( ret );
  store.commit('addErrorMessage', {origin: 'findModelEnd', message: 'ModelName not found after end, format should be:\nmodel modelname\n ...declarations...\nequation\n ...equations...\nannotation(...);\nend modelname;'});

  return ret;
}

function matchEquation( text ){
  // let mat = [...text.matchAll(/\n[ \t]*equation[ \t]*[\/]*.*/g)]; regex should only lead to one result..
  let mat = {index: text.search(/\n[ \t]*equation[ \t]*[\/]*.*/g)};
  // Vue.$log.info('matchEquation mat', mat );

  if (mat){ //find the newline after equation, including any comments..
    let ieol = text.substring(mat.index+1).search( /\n/ );
    // Vue.$log.info('matchEquation eol', ieol );
    mat.indexAfter = mat.index + 1 + ieol + 1;
    // Vue.$log.info('fragment ' + mat.index + ' : ' + mat.indexAfter, text.substring( mat.index, mat.indexAfter));
  }
  return mat;
}

function matchModelAnnotation( text ){
  let mat = [...text.matchAll(/\n[ \t]*annotation[ \t]*\([ \t]*[\/]*.*/g)]; // this will fail in OMedit models where annotation is on the second line?
  // let mat = {index: text.search(/\n[ \t]*annotation[ \t]*\([ \t]*[\/]*.*/g)};
  mat = mat[0];
  if (mat){
    mat.indexAfter = mat.index + mat[0].length;
  }
  // Vue.$log.info('matchAnnotation mat', mat );
  return mat;
}

function checkModelAnnotationTrailingComma( syntax ){
  //the rule from OMC: terminate each line with a comma, no comma on the last line:
  // annotation(
  // experiment(StartTime=0, StopTime=1, Tolerance=1e-3, Interval=0.1), //yes
  // __Mechanomy_analyze(plot2D={x:time, y: "body1.r_0[2]", linestyle: dash, linecolor:red, pointstyle: dot, pointcolor: green}), //yes
  // __Mechanomy_analyze(plot2D={x:time, y: revolute1.phi, linestyle: dash, linecolor:red, pointstyle: dot, pointcolor: green}), //NO COMMMA
  // );

  let line = [...syntax.matchAll( /\_\_Mechanomy\_analyze.*/g )]; //matches the full analyze line
  // Vue.$log.info( 'line', line );

  // Vue.$log.info( 'lline', lastline );
  if (line && 0 < line.length){
    let lastComma = line[line.length-1][0].match(/\}\s*\)\s*(.)/); //comma - whitespace - \n\r
    // Vue.$log.info( 'lco', lastComma);

    if (lastComma && lastComma.length == 2 && lastComma[1] == ','){
      let ret = {valid: false, message:"Remove the trailing comma from line '" + line + "'" };
      Vue.$log.warn( ret );
      return ret;
    }
  }

  let ret = {valid: true, message:""}
  // Vue.$log.info( ret );
  return ret;
}

function checkModelSemicolon( syntax ){
  //require each line in model and equation sections to be terminated with a semicolon

  // Vue.$log.info('checkModelSemicolon:');

  let matchName = matchModelName( syntax );
  // console.log('matchModelName',matchName);

  let matchEqn = matchEquation( syntax );
  // console.log('matchEquation',matchEqn);

  let matchAnn = matchModelAnnotation( syntax);
  // console.log('matchAnnotation',matchAnn);
  // console.log('ssub', syntax.substring( matchEqn.indexAfter, matchAnn.index-2 ) );

  // let linenum = [...syntax.substring( matchName.indexAfter, matchEqn.index+1 ).matchAll(/([^\n]+)\n/g)]
  // let missingSemiDeclare = checkSemicolonInSection( [...syntax.substring( matchName.indexAfter, matchEqn.index+1 ).matchAll(/([^\n]+)\n/g)] );
  // let missingSemiEquate  = checkSemicolonInSection( [...syntax.substring( matchEqn.indexAfter, matchAnn.index ).matchAll(/([^\n]+)\n/g)] );

  //super clunky, codeM has a function for this?
  store.commit('clearOriginMessages',  'checkModelSemicolon' ); //clear errors before potentially adding them again
  let topToEquations = [...syntax.substring( 0, matchEqn.index+1 ).matchAll(/\n/g)];
  // console.log('topToEquations', topToEquations);
  let declarations = [...syntax.substring( matchName.indexAfter, matchEqn.index+1 ).matchAll(/([^\n]+)\n/g)];
  let equations = [...syntax.substring( matchEqn.indexAfter, matchAnn.index ).matchAll(/([^\n]+)\n/g)];
  let missingSemiDeclare = checkSemicolonInSection( declarations, topToEquations.length-declarations.length+1 );
  let missingSemiEquate  = checkSemicolonInSection( equations, topToEquations.length+2 );

  let missingSemi = missingSemiDeclare.concat(missingSemiEquate);
  // console.log('missingSemi', missingSemi);


  if (0 < missingSemi.length){
    let msg = '';
    for (let line of missingSemi){
      msg = msg + line + '\n';
    }
    msg = msg.substring(0,msg.length-1); //trim last \n
    return {valid: false, message: msg };
  } else {
    return {valid: true};
  }
}

function checkMultilineComment( syntax ){ //just say not supported until parser trie rewrite
  store.commit('clearOriginMessages',  'checkMultilineComment' ); //clear errors before potentially adding them again
  let isMultiCommentO = [...syntax.matchAll( /\/\*/g )]; // is it whitespace + (multi) line comment?
  let isMultiCommentC = [...syntax.matchAll( /\*\//g )]; // is it whitespace + (multi) line comment?
  if (isMultiCommentO!=null && 0 < isMultiCommentO.length){
    // console.log('isMultiCommentO:', isMultiCommentO);
    let msg = 'Multiline comments /* .. */ not supported, please remove';
    store.commit('addErrorMessage', {origin: 'checkMultilineComment', message: msg} );
    return {valid: false, message: msg };
  }
  if (isMultiCommentC!=null && 0 < isMultiCommentC.length){
    // console.log('isMultiCommentC:', isMultiCommentC);
    let msg = 'Multiline comments /* .. */ not supported, please remove';
    store.commit('addErrorMessage', {origin: 'checkMultilineComment', message: msg} );
    return {valid: false, message: msg };
  }
  return {valid: true };
}

function checkSemicolonInSection( lines, sectionStartingLineNumber ){
  let missingSemi = [];
  // console.log('lines',lines);
  // for (let line of lines){
  lines.forEach( (line, index) => {
    let lineNo = index+sectionStartingLineNumber;

    let asemiline = line[0].match( /^[^;]+$/ ); // from the start to the end, match all that are not semicolon
    if (asemiline  != null){ // if there is a match, it is either whitespace, a comment, or equation/control structure..
      // console.log('asemiline:[' + line[0] + ']', asemiline);

      let isWhitespace = asemiline[0].match( /^\s*$/ );
      // if (isWhitespace != null){
      //   console.log('isWhitespace:', isWhitespace);
      // }
      let isComment = asemiline[0].match( /^\s*\/\// ); // if no semicolon, comment must precede regular characters
      // if (isComment != null){
      //   console.log('isComment:', isComment);
      // }

      let isWhen = asemiline[0].match( /when\s*\{/ ); // if no semicolon, comment must precede regular characters
      // if (isWhen != null){
      //   console.log('isWhen:', isWhen);
      // }

      if( isWhitespace==null && isComment==null && isWhen==null){
        // console.log('missing asemiline:[' + line[0] + ']', asemiline);
        let missing = 'Line '+ lineNo +' [' + line[0].replace('\n','') + '] is missing a semicolon.';
        // Vue.$log.warn(missing);
        missingSemi.push( missing );
        store.commit('addErrorMessage', {origin: 'checkModelSemicolon', message: missing} );
      }
    } else { //has a semicolon, is it within a comment?
      let commsemi = line[0].match( /([^;]+)(\/\/)(;).*/ ); // from the start to the end, match all that are not semicolon
      if (commsemi != null){
        // console.log(commsemi);
        let missing = 'Line '+ lineNo +' [' + line[0].replace('\n','') + '] is missing a semicolon.';
        store.commit('addErrorMessage', {origin: 'checkModelSemicolon', message: missing} );
        // Vue.$log.warn(missing);
        missingSemi.push( missing );
      }

    }
  });
  return missingSemi;
}

function getSimulateOptions(simulateText) {
  //returns a json of the simulation options
  //simulate(className, [startTime], [stopTime], [numberOfIntervals], [tolerance], [method], [fileNamePrefix], [options], [outputFormat], [variableFilter], [cflags], [simflags]) Example command: simulate(A);
  //         simulateText = `simulate
  // startTime = 1.1;
  // stopTime = 3.3;
  // numberOfIntervals = 30.1;
  // tolerance = 0.0001;
  // method = dassl;
  // `;

  let matchStart = simulateText.match(/[sS]tartTime\s*=\s*([0-9\.]+);/);
  let matchStop = simulateText.match(/[sS]topTime\s*=\s*([0-9\.]+);/);
  let matchIntervals = simulateText.match( /numberOfIntervals\s*=\s*([0-9\.]+);/);
  let matchTolerance = simulateText.match(/tolerance\s*=\s*([0-9\.]+);/);
  let matchMethod = simulateText.match(/method\s*=\s*(\w+);/);

  let simOptions = {};
  if (matchStart) {
    //using truthiness of match
    simOptions.startTime = parseFloat(matchStart[1]);
  } else {
    simOptions.startTime = 0.0;
  }
  if (matchStop) {
    simOptions.stopTime = parseFloat(matchStop[1]);
  } else {
    simOptions.stopTime = simOptions.startTime + 1.0;
  }
  if (matchIntervals) {
    simOptions.numberOfIntervals = parseInt(matchIntervals[1]);
  } else {
    simOptions.numberOfIntervals = 20;
  }
  if (matchTolerance) {
    simOptions.tolerance = parseFloat(matchTolerance[1]);
  } else {
    simOptions.tolerance = 1e-3;
  }
  if (matchMethod) {
    simOptions.method = matchMethod[1];
  } else {
    simOptions.method = 'dassl';
  }
  Vue.$log.info('simOptions: ' + JSON.stringify( simOptions ) );
  return simOptions;
} //getSimulateOptions

function findModelAnnotation(syntax) { //'annotation' may occur multiple places, want the 'last' one that refers to the model
  // let herestr = 'findModelAnnotation: ';

  //occurs after 'equation' and before 'end ModelName'
  //contains 'experiment', '__Mechanomy...', '__VendorSpecific...'
  //no annotation within an annotation

  //  annotation  ( \n
  let annOpen = [...syntax.matchAll(/\w*annotation\w*\(/g)]; //make iterator object into an array
  // Vue.$log.info( 'annotation opening:', annOpen[annOpen.length-1] );

  if (annOpen.length < 1){
    let ret = {valid: false, annotation:"", message: "Did not find the model annotation, should be after 'equation' as :\nannotation(\n  experiment(...),\n  __Mechanomy_analyze(...)\n);\nend ModelName;"};
    Vue.$log.warn( ret );
    return ret;
  }


  // ); \n end ModelName;
  let annClose = [...syntax.matchAll(/\w*\)\w*;[\n\r]*end/g)]; //make iterator object into an array
  // Vue.$log.info( 'annotation close:', annClose );

  if (annClose.length < 1){
    let ret = {valid: false, annotation:"", message: "Did not find the model annotation's closing parenthese, should be:\nannotation(\n  experiment(...),\n  __Mechanomy_analyze(...)\n);\nend ModelName;"};
    Vue.$log.warn( ret );
    return ret;
  }


  let annotation = syntax.substring( annOpen[annOpen.length-1].index, annClose[annClose.length-1].index );
  // Vue.$log.info( 'annotation=', annotation );
  return {valid: true, annotation: annotation, message:""};
}


