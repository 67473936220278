<template>
  <div id="RunningModel">
    <div id="runningBox" v-if="showAd">
      <RunningAd />
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
      </div>
    </div>
  </div><!--RunningModelVue-->
</template>
<style scoped>
#runningBox {
  /* border: 2px solid gray; */
  /* border-radius: 3px 3px; */

  /* background-color: #ffffffee; */
  /* background-color: #eeeeeeee; */
  /* box-shadow: 10px 10px #eaeaeadd; */
  /* margin-right: 12px; */

  z-index: 100;
}

</style>

<script type="text/javascript">

// import Vue from "vue";
import RunningAd from "@/components/RunningAd";
import store from '@/store/Store';

export default {
  name: "RunningModel",
  components: { RunningAd },
  data() {
    return {
    };
  },
  computed: {
    showAd(){
      // console.log('showAd recomputed: '+store.getters.getShowAd);
      // return true;
      return store.getters.getShowAd;
    }
  },

  mounted(){
  },
  beforeDestroy(){
  },
  methods: {
  }
}

</script>



