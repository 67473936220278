<template>
  <div id="model">
    <div id="container">
      <!-- <Header /> -->
      <!-- <Canvas /> -->
      <!-- <Analyze /> -->
      <ModelEditor />
    <!--   <!&#45;&#45; <Sim /> &#45;&#45;> -->
    <!--   <!&#45;&#45; <GetModels /> &#45;&#45;> -->
    </div>
  </div>
</template>
<style scoped>
#container{
  position: relative;
  z-index: 0;
  /* background-color: #ff999922; */
  /* border: 1px solid black; */
}
</style>


<script type="text/javascript">
import Vue from 'vue';
import Header from '../components/Header'
import ModelEditor from '../components/ModelEditor'
import * as MEH from '@/common/ModelEditorHelper';
import * as UserOps from '../common/UserOps';

export default {
  name: 'Model',
  components: {
    // Header, //include children so Model can call them (above)
    ModelEditor
  },
  data(){
    return{
      session: {
        loggedIn: false,
        userName: 'Bobby',
        modelName: 'Bouncer'
      },
      codem: null,
      datas: []
    }
  },

  beforeMount(){
    // this.$log.debug('Model.vue beforeMount()');
    // this.$log.debug('getLoggedIn = ' +store.getters.getLoggedIn );
    // this.$log.debug('getLoggedIn = ' + this.$cookies.get('loggedIn') );

    // if (this.$route.params.modelID){
    //   Vue.$log.info('Model.vue: loading model ' + this.$route.params.modelID);
    //   MEH.loadModel( this.$route.params.modelID ); //filter nasty?
    // }
  },

  mounted(){
   // this.$log.debug('Model.vue mounted()');
  },
  beforeUpdate(){
  },
  updated(){
  },

  methods: {
  }//methods

}//exportDefault




</script>


