<template>
  <div id="RunningAd">
    <!-- <h1>This simulation {{partnerName}}:</h1> -->
    <!-- <h1>Thanks to {{partnerName}} for making the wheels turn:</h1> -->
    <h1>This simulation is brought to you by:</h1>
    <a href="https://composer.mechanomy.com/sponsors" target="blank">
      <img src="https://api.mechanomy.com:2442/api/ad" alt="Thanks to our sponsor"/>
    </a>
  </div><!--RunningModelVue-->
</template>

<style scoped>
#RunningAd{
  /* background-color: #ffffffee; */
  /* box-shadow: 10px 10px #eaeaeadd; */
}
h1 {
  font-size: 1.2em;
  /* background-color: #fafafaff; */
  /* background-color: #f8f8f8ff; */
  text-align: center;
}
img {
  width: 100%;
  border: 2px solid black;
  /* height: 400px; */
}
</style>

<script type="text/javascript">

import { Component, Vue } from 'vue-property-decorator';

// import * as MEH from '@/common/ModelEditorHelper';
// import * as UTY from '@/common/Utility';
// import store from '@/store/Store'

export default
@Component({
  components: {},
  props: {}
})
class RunningAd extends Vue {
  show = true; //show the result, used to hide the result when deleted
  adimg = {};

  mounted(){
    // this.getAd();
  }
}
</script>


