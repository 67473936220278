import Vue from 'vue';

export const resultStore = {
  state: {
    resultID: '',
    modelName: '',
    dateRan: '',
    times: {'compile':0, 'simulate':0, 'total':0},
    valid: false,
    data: [] //placeholder, to be replaced by result object from the server
  },
  getters: {
    areResultsValid(state){
      // Vue.$log.debug('store.areResultsValid()', state.valid);
      if (!state.valid)
        return false;
      return state.valid;
    },
    getResultID(state){
      if (!state.resultID)
        return false;
      return state.resultID;
    },
    getResultData(state){
      if (!state.data)
        return false;
      return state.data;
    },
    getResultObject(state){
      return state;
    }

  },
  mutations: {
    setResultID(state, rid){
      state.resultID = rid;
    },
    invalidateResults( state ){
      state.valid = false;
    }
  },
  actions: {
    addNewResult( {state,commit,rootState}, newResult){ /* eslint-disable-line */ //newResult = {_id, data:, date:, modelName:,
      // Vue.$log.info('addNewResult', newResult );
      state.resultID = newResult._id;
      state.modelName =  newResult.userModelName;
      state.dateRan = newResult.dateRan;
      state.times.compile = newResult.times.compile;
      state.times.simulate = newResult.times.simulate;
      state.times.total = newResult.times.total;
      state.valid = true;
      state.data = newResult.data;
    },
    resultLogOut( {state,commit,rootState} ){ /* eslint-disable-line */
      state.resultID = '';
      state.valid = false;
      state.data = [];
    }
  }
}


