import Vue from 'vue';
import store from '@/store/Store';
// import * as ANA from '@/common/AnalyzeHelper';
// import * as GPH from '@/common/GraphHelper';
import * as UOP from '@/common/UserOps';
import * as UTY from '@/common/Utility';
import * as EXM from '@/common/ExampleModels';


export function loadModel( mdlid ){  // load a model from the server by id
  let herestr = 'MEH.loadModel: ';
  // Vue.$log.info( 'MEH.loadModel mdlid' + mdlid );

  return new Promise( (resolve,reject)=>{
    if (!store.getters.getAPIToken){
      // Vue.$log.info('getToken');
      UOP.getAPIToken()
        .then( ()=>{
          // Vue.$log.info('postLoadModel');
          postLoadModel(mdlid).then( (syn)=>{
            resolve(syn);
          })
          .catch( (err)=>{
            let msg = 'get token err';
            Vue.$log.info(msg);
            reject( UTY.promiseRejected( 'error',msg) );
          });
        })
      .catch((err)=>{
        Vue.$log.info('rejectex');
        reject( UTY.promiseRejected( 'error','rejectex' + err) );
      });
    } else {
      // Vue.$log.info('else');
      postLoadModel(mdlid).then( (syn)=>{
        resolve(syn);
      })
        .catch( (err)=>{
          let msg = 'have token err';
          Vue.$log.info(msg);
          reject( UTY.promiseRejected( 'error',msg) );
        });
    }
  });
}

function postLoadModel(mdlid){
  let herestr = 'MEH.postLoadModel: ';
  // Vue.$log.info(herestr);
  return new Promise( (resolve,reject)=>{
    Vue.prototype.$axios.post( '/api/model/get/'+ mdlid )
      .then( (res) => {
        // Vue.$log.info( 'received model ' + JSON.stringify( res.data ) );
        if (res.data.model != undefined && res.data.model!=false){

          //set store
          store.commit('setModelID', res.data.model._id );
          store.commit('setModelName', res.data.model.userModelName );
          store.commit('setModelSyntax', res.data.model.modelSyntax);
          store.commit('invalidateResults');

          resolve( res.data.model.modelSyntax );
            // Vue.$log.info( 'set model in store');
        } else {
          if (res.data.message != undefined){
            store.commit('addErrorMessage', {origin: 'loadModel', message: res.data.message} );
            Vue.$log.error( res.data.message );
            reject( UTY.promiseRejected( 'error', res.data.message) );
          } else if (res.message != undefined){
            store.commit('addErrorMessage', {origin: 'loadModel', message: res.message} );
            Vue.$log.error( res.message );
            reject( UTY.promiseRejected( 'error', res.message) );
          } else {
            let msg = 'loadModel had an error';
            store.commit('addErrorMessage', {origin: 'loadModel', message: msg });
            Vue.$log.error( msg + ':' );
            console.log(res);
            reject( UTY.promiseRejected( 'error', msg ) );
          }
        }
      }, (err)=>{
        let msg = herestr + 'then had error ' + JSON.stringify(err);
        Vue.$log.error( msg );
        reject( UTY.promiseRejected('error', msg) );
      })
      .catch( (err) => {
        Vue.$log.error( herestr + ' had error getting model ' + JSON.stringify(err) );
        reject( UTY.promiseRejected( 'error', herestr + ' had error ' + JSON.stringify(err) ));
      });
  });
}

export function loadModelExample(which){
  let syn = EXM.blank;

  if (which == 'blank'){
    syn = EXM.blank;
  }
  if (which == 'formatReference'){
    syn = EXM.formatReference;
  }
  if (which == 'bouncingBall'){
    syn = EXM.bouncingBall;
  }
  if (which == 'simplePendulum'){
    syn = EXM.simplePendulum;
  }

  store.commit('setModelSyntax', syn);
  store.commit('invalidateResults');
  return syn;
}

//simulate/////////////////////////////////////////////////////////////////////////////////////////
export function parseSimulateFromModel(syntax) { // parse the model into parts
  let tlen = syntax.length;

  let sarr = syntax.match(/simulate/);
  let sind = sarr.index;

  let aarr = syntax.substring(sind, tlen).match(/analyze/);
  let aind = aarr.index + sind;

  let earr = syntax.substring(aind, tlen).match(/end/);
  let eind = aind + earr.index;

  let parts = {
    model: syntax.substring(0, sind - 1) + syntax.substring(eind, tlen), //-1 to not include the newline preceding simulate
    simulate: syntax.substring(sind, aind - 1),
    analyze: syntax.substring(aind, eind - 1)
  };
  return parts;
} //parseModel

//send model to server, returns modelID
export function sendModelToServer() {
  Vue.$log.debug('sendModelToServer: Sending model to server at ' + Date.now());
  // console.log( 'sendModel:', store.getters.getModelSyntax);


  return new Promise((resolve,reject) => {
    let reqData = { modelName: store.getters.getModelName,
      syntax: store.getters.getModelSyntax,
      modelID: store.getters.getModelID }; // should agree with postModelAdd

    Vue.prototype.$axios.post( '/api/model/add', reqData)
      .then((res) => {
        store.commit('setIsServerDown', false);
        if (res.data.success){
          store.commit('setModelID', res.data.modelID );
          // pthis.addingModel = false;
          Vue.$log.debug('sendModelToServer: returned with modelID' , res.data.modelID);

          resolve( res.data.modelID );
        } else {
          reject( UTY.promiseRejected( 'warn', res.data.message ));
        }
      })
      .catch((err) => {
        Vue.$log.error('sendModelToServer: apiModelAdd caught error:', err);
        // pthis.addingModel = false;
        reject(UTY.promiseRejected( 'error', 'apiModelAdd returned error: ' + JSON.stringify( err )));
      });//apimodeladd
  });
}

//run model simulation, returns simulation info object
export function runSimulation(){

  return new Promise( (resolve,reject) => {
    //simulate model
    store.commit('showAd');
    store.commit('clearOriginMessages',  'runSimulation' );

    let info = {'messages':[], severity:'info'};
    Vue.$log.info('runSimulation: Simulating model on server at ' + Date.now());
    Vue.prototype.$axios.post( '/api/model/simulate/' + store.getters.getModelID )
      .then((res) => {
        // Vue.$log.debug('runModel: apiModelSimulate gave result id of ' + res.data.resultID);
        // Vue.$log.debug('sim response',res.data);

        store.commit('hideAd');

        if (res.data.success){
          store.commit('setResultID', res.data.resultID );
          Vue.$log.info('setResultID', res.data.resultID);

          if (res.data.omcMessages.simulated.messages){
            info.messages.push(res.data.omcMessages.simulated.messages);
          }

          if (res.data && res.data.omcMessages.success){ //simulation succeeded, show sim info
            Vue.$log.debug('addInfoMessage: ', res.data.message );
            store.commit('addInfoMessage', {origin: 'runSimulation', message: res.data.message} );

            // info.showSimInfo = true;
            // info.showSimError = false;
          } else {
            Vue.$log.debug('success but not...: ', res.data);
            store.commit('addErrorMessage', {origin: 'runSimulation', message: res.data.message} );


            // info.showSimInfo = false;
            // info.showSimError = true;
            // Vue.$log.debug('showSimError');
          }

        } else {
          store.commit('invalidateResults');
          Vue.$log.debug('runSimulation failed, invalidateResults');
          Vue.$log.error('Simulation failed, server error message:', res.data);

          if( res.data.omcMessages && res.data.omcMessages.checkError ){ //most likely a build error
            // the full message is like
            //"[/tmp/composer_5d83cc4ea0feae34196f3477_27684U3pBco4Yujc0/FirstGrounded.mo:17:3-17:59:writable] Error: Class Mechanics.Rotational.Components.Damper not found in scope FirstGrounded.\nError: Error occurred while flattening model FirstGrounded\n"
            // so trim off the filepath between brackets      \   / and pass along the error message
            let em = res.data.omcMessages.checkError.match( /\[.*\](.*)/ );// [1] = rest of error message
            // console.log('em',em);
            let emline = res.data.omcMessages.checkError.match( /\[.*mo\:(.*\:.*-.*\:.*)\:.*\](.*)/ );
            // console.log('emline',emline);

            if (emline.length == 3){
              store.commit('addErrorMessage', {origin: 'runSimulation', message: 'Line '+ emline[1] + ': ' + emline[2] } );
              info.messages.push( res.data.omcMessages.checkError );
            } else if (em.length == 2){
              store.commit('addErrorMessage', {origin: 'runSimulation', message: em[1] } );
              info.messages.push( res.data.omcMessages.checkError );
            } else {
              store.commit('addErrorMessage', {origin: 'runSimulation', message: res.data.message} );
              info.messages.push( 'c: '+res.data.message );
            }

          } else {
            store.commit('addErrorMessage', {origin: 'runSimulation', message: res.data.message} );
            info.messages.push( res.data.message );
          }

          reject(info);
        }
      })
      .then( () => {
        Vue.$log.debug('runSimulation resolve with info', info );
        resolve(info);
      })
      .catch((err) => {
        store.commit('hideAd');
        store.commit('invalidateResults');

        Vue.$log.error('runSimulation: apiModelSimulate caught error:', err);

        info.message.push('runSimulation: apiModelSimulate caught error: '+ err);
        info.severity = 'error';

        reject(info);
      }); //apimodelsimulate
  });
}

//retrieve results, returns result id
export function retrieveResults(){
  // let herestr = 'MEH.retrieveResults: ';

  return new Promise( (resolve,reject) => {
    store.commit('invalidateResults');

    //get results
    if (!store.getters.areResultsValid){
      // Vue.$log.debug('retrieveResults: Getting results at [' + store.getters.getResultID );
      Vue.prototype.$axios.post( '/api/result/get/' + store.getters.getResultID )
        .then( (res) =>{
          // console.log('res'); console.log(res);
          if (res.data.valid == true){

            // Vue.$log.debug('retrieveResults: received results to plot:'); Vue.$log.debug(result);
            // Vue.$log.info('received results:', res.data.result); //res.data.result[.data, .dateRan, .modelID, .times...]

            // store.commit('setResultID', res.data.result._id);
            // store.commit('setResultModelName', res.data.result.userModelName);
            // store.commit('setResultDateRan', res.data.result.dateRan);
            // store.commit('setResultTimes', res.data.result.times.compile, res.data.result.times.simulate, res.data.result.times.total);
            // store.dispatch('addNewResultData', res.data.result.data)
            store.dispatch('addNewResult', res.data.result)
              .then( ()=>{
                // Vue.$log.warn(' done adding results ');
                resolve( res.data.result._id );
              });

          } else {
            Vue.$log.error('received result but result is not valid, message: ' + res.data.message);
            // pthis.gettingResults = false;
            reject(false);
          }
        })
        // .then( () => { resolve(true) } )
        .catch(function(err) {
          Vue.$log.error('retrieveResults: apiModelResults caught error:', err);
          // pthis.gettingResults = false;
          reject(false);
          // return false;
        // }) //apimodelget
        // .finally(function(res) {
          // pthis.gettingResults = false;
          // return true;
        });
    }
  });
}

//retrieve results, returns result id
export function retrieveResultID( resultID ){
  // let herestr = 'MEH.retrieveResultID: ' + resultID + ' ';

  return new Promise( (resolve,reject) => {
    store.commit('invalidateResults');

    //get results
    if (!store.getters.areResultsValid){
      Vue.$log.debug('retrieveResults: Getting results at [' + store.getters.getResultID );
      Vue.prototype.$axios.post( '/api/result/get/' + resultID )
        .then( (res) =>{
          // console.log('res'); console.log(res);
          if (res.data.valid == true){

            // Vue.$log.debug('retrieveResults: received results to plot:'); Vue.$log.debug(result);
            Vue.$log.info('received results:', res.data.result); //res.data.result[.data, .dateRan, .modelID, .times...]

            // store.commit('setResultID', res.data.result._id);
            // store.commit('setResultModelName', res.data.result.userModelName);
            // store.commit('setResultDateRan', res.data.result.dateRan);
            // store.commit('setResultTimes', res.data.result.times.compile, res.data.result.times.simulate, res.data.result.times.total);
            // store.dispatch('addNewResultData', res.data.result.data)
            store.dispatch('addNewResult', res.data.result)
              .then( ()=>{
                // Vue.$log.warn(' done adding results ');
                resolve( res.data.result._id );
              });

          } else {
            Vue.$log.error('received result but result is not valid, message: ' + res.data.message);
            // pthis.gettingResults = false;
            reject(false);
          }
        })
        // .then( () => { resolve(true) } )
        .catch(function(err) {
          Vue.$log.error('retrieveResults: apiModelResults caught error:', err);
          // pthis.gettingResults = false;
          reject(false);
          // return false;
        // }) //apimodelget
        // .finally(function(res) {
          // pthis.gettingResults = false;
          // return true;
        });
    }
  });
}


