import Vue from 'vue';

let verbose = false;
export const simAdStore = {
  state: {
    showAd: false,
    shownIDs: []
  },
  getters: {
    getShowAd( state ){
      return state.showAd;
    },
  },
  mutations: {
    showAd( state ){
      // console.log('showAd');
      state.showAd = true;
    },
    hideAd( state ){
      // console.log('hideAd');
      state.showAd = false;
    }
  }
}
